import React, { createContext, useContext, useState, useEffect } from 'react'
const ServiceContext = createContext();
const ServiceProvider = ({ children }) => {
    const [mobileAppService, setMobileAppService] = useState([]);
    const [mobileAppbackgroundImage, setMobileAppBackgroundImage] = useState('');
    const [marketingService, setMarketingeAppService] = useState([]);
    const [marketingbackgroundImage, setMarketingBackgroundImage] = useState('');
    const [websiteService, setWebsiteService] = useState([]);
    const [websitebackgroundImage, setWebsiteBackgroundImage] = useState('');
    const [brandService, setBrandService] = useState([]);
    const [brandbackgroundImage, setBrandBackgroundImage] = useState('');
    const [brochureService, setBrochureService] = useState([]);
    const [brochurebackgroundImage, setBrochureBackgroundImage] = useState('');
    const changeBackgroundImage = (img, state) => {
        state(img)
    }

    const fetchDataMobileApp = () => {
        fetch("https://cms.prosouls.net/api/service/mobile")
            .then(response => {
                return response.json()
            })
            .then(data => {
                setMobileAppService(data.data)
                changeBackgroundImage("https://cms.prosouls.net/assets/services/background_image/" + data.data[0].background_image, setMobileAppBackgroundImage)
            })
    }

    const fetchDataMarketing = () => {
        fetch("https://cms.prosouls.net/api/service/marketing")
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setMarketingeAppService(data.data);
                changeBackgroundImage(
                    "https://cms.prosouls.net/assets/services/background_image/" +
                    data.data[0].background_image
                    , setMarketingBackgroundImage);
            });
    };

    const fetchDataWebsite = () => {
        fetch("https://cms.prosouls.net/api/service/website")
            .then(response => {
                return response.json()
            })
            .then(data => {
                setWebsiteService(data.data)
                changeBackgroundImage("https://cms.prosouls.net/assets/services/background_image/" + data.data[0].background_image, setWebsiteBackgroundImage)
            })
    }

    const fetchDataBrand = () => {
        fetch("https://cms.prosouls.net/api/service/brand")
            .then(response => {
                return response.json()
            })
            .then(data => {
                setBrandService(data.data)
                changeBackgroundImage("https://cms.prosouls.net/assets/services/background_image/" + data.data[0].background_image, setBrandBackgroundImage)
            })
    }

    const fetchDataBrochure = () => {
        fetch("https://cms.prosouls.net/api/service/brochure")
          .then(response => {
            return response.json()
          })
          .then(data => {
            setBrochureService(data.data)
              changeBackgroundImage("https://cms.prosouls.net/assets/services/background_image/"+ data.data[0].background_image,setBrochureBackgroundImage)
          })
      }

      useEffect(() => {
        fetchDataBrochure()
        },[])

    useEffect(() => {
        fetchDataBrand()
    },[])

    useEffect(() => {
        fetchDataWebsite()
    }, [])

    useEffect(() => {
        fetchDataMarketing();
    }, []);

    useEffect(() => {
        fetchDataMobileApp();
    }, []);
    const value = {
        mobileAppService: [mobileAppService, setMobileAppService],
        mobileAppbackgroundImage: [mobileAppbackgroundImage, setMobileAppBackgroundImage],
        marketingService: [marketingService, setMarketingeAppService],
        marketingbackgroundImage: [marketingbackgroundImage, setMarketingBackgroundImage],
        websiteService: [websiteService, setWebsiteService],
        websitebackgroundImage: [websitebackgroundImage, setWebsiteBackgroundImage],
        brandService: [brandService, setBrandService],
        brandbackgroundImage: [brandbackgroundImage, setBrandBackgroundImage],
        brochureService: [brochureService, setBrochureService],
        brochurebackgroundImage: [brochurebackgroundImage, setBrochureBackgroundImage] 
    }

    return (
        <ServiceContext.Provider value={value}>
            {children}
        </ServiceContext.Provider>
    )
}
const useService = () => useContext(ServiceContext);
export { useService, ServiceProvider } 
