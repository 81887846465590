import { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import OurApproachPoint1 from "../../assets/our-approach/1.webp";
import OurApproachPoint2 from "../../assets/our-approach/2.webp";
import OurApproachPoint3 from "../../assets/our-approach/3.webp";
import OurApproachPoint4 from "../../assets/our-approach/4.webp";
import OurApproachPoint5 from "../../assets/our-approach/5.webp";
import OurApproachPoint6 from "../../assets/our-approach/6.webp";
import "./about.css";
import Aos from "aos";
import "aos/dist/aos.css";
function About() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  });
  return (
    <>
      <div className="cstm-our-approach-container">
        <div className="our-approach-parent">
          <div className="our-approach-heading">
            <h1 className="clr-txt">STRATEGY & APPROACH</h1>
          </div>
          <div className="our-approach-content-para">
            <p className="clr-txt text-center">
            We at Prosouls take pride in being interactive and unique in differentiating you from your competitors.
We believe in the importance of collaboration and spending efforts to understand our clientele and their
organizations. We are motivated by results to produce digital design achievements that reflect your
values. Our insatiable desire to succeed drives us to constantly innovate.
            </p>
          </div>
          <div className="our-approach-bullet-points">
            <ul className="our-approach-bullet-ul">
                <li className="our-approach-bullet-li">
                    <div className="our-approach-bullet-parent">
                        <div className="our-approach-bullet-inner-icon">
                            <img alt="Research & Planning" width="100%" height="100%" src={OurApproachPoint6} />
                        </div>
                        <div className="our-approach-bullet-inner-text">
                            <p className="clr-txt">
                            Research & Planning
                            </p>
                        </div>
                    </div>
                </li>
                <li className="our-approach-bullet-li">
                    <div className="our-approach-bullet-parent">
                        <div className="our-approach-bullet-inner-icon">
                            <img alt="Content Creation" width="100%" height="100%" src={OurApproachPoint1} />
                        </div>
                        <div className="our-approach-bullet-inner-text">
                            <p className="clr-txt">
                            Content Creation
                            </p>
                        </div>
                    </div>
                </li>
                <li className="our-approach-bullet-li">
                    <div className="our-approach-bullet-parent">
                        <div className="our-approach-bullet-inner-icon">
                            <img alt="Starting Campaigns" width="100%" height="100%" src={OurApproachPoint3} />
                        </div>
                        <div className="our-approach-bullet-inner-text">
                            <p className="clr-txt">
                            Starting Campaigns
                            </p>
                        </div>
                    </div>
                </li>
                <li className="our-approach-bullet-li">
                    <div className="our-approach-bullet-parent">
                        <div className="our-approach-bullet-inner-icon">
                            <img alt="Analyzing & Optimizing" width="100%" height="100%" src={OurApproachPoint4} />
                        </div>
                        <div className="our-approach-bullet-inner-text">
                            <p className="clr-txt">
                            Analyzing & Optimizing
                            </p>
                        </div>
                    </div>
                </li>
                <li className="our-approach-bullet-li">
                    <div className="our-approach-bullet-parent">
                        <div className="our-approach-bullet-inner-icon">
                            <img alt="Feedback" width="100%" height="100%" src={OurApproachPoint2} />
                        </div>
                        <div className="our-approach-bullet-inner-text">
                            <p className="clr-txt">
                            Feedback
                            </p>
                        </div>
                    </div>
                </li>
                <li className="our-approach-bullet-li">
                    <div className="our-approach-bullet-parent">
                        <div className="our-approach-bullet-inner-icon">
                            <img alt="Maintenance" width="100%" height="100%" src={OurApproachPoint5} />
                        </div>
                        <div className="our-approach-bullet-inner-text">
                            <p className="clr-txt">
                            Maintenance
                            </p>
                        </div>
                    </div>
                </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
