import "bootstrap/dist/css/bootstrap.min.css";
import "../../careers/careers";
import { useEffect } from "react";
import * as React from "react";
import ParticlesBackground from "../../global-component/particle-background/particle-background";
import "./products.css";
import Footer from "../../footer/footer";
import GlowingButton from "../../global-component/glowing-btn/glowing-btn";
import PearlProductImage from '../../../assets/products/pearl-cummunication-icon.png'
import MiddlewareProductImage from '../../../assets/products/middleware-connection-products.png'
import RetailProductImage from '../../../assets/products/retail-analytics-products.png'
import hauteProductImage from '../../../assets/products/haute-logic-products.png'
import SyshcmProductImage from '../../../assets/products/syshcm-products.png'
import TripsProductImage from '../../../assets/products/trips-products.png'
import Aos from 'aos';
import 'aos/dist/aos.css'
function Products() {
  useEffect(() => {
    Aos.init({ duration: 2000 })
  })
  return (
    <>
      <ParticlesBackground />
      <div className="products-expertise-banner">
        <div class="container">
          <div class="products-expertise-first-module">
            <h5 className="clr-text-white text-uppercase">
              PRODUCTS
            </h5>
            <h1 className="clr-text-white">
              Committed to solving
              your unique, domainspecific challenges.
            </h1>
            <div className="products-expertise-first-module-btn-parent">
              <GlowingButton />
            </div>
          </div>
        </div>
      </div>
      <div className="products-expertise-second-module-parent">
        <div class="container">
          <div className="products-expertise-second-module-flex">
            <div className="products-expertise-second-module-column one">
              <div data-aos="slide-right" className="products-parralax-container">
                <h1 className="products-stroke-font">Domain</h1>
                <h1 className="products-stroke-font">Products</h1>
              </div>
            </div>
            <div data-aos="slide-left" className="products-expertise-second-module-column solid-bg">
              <div className="products-expertise-solid-bg-content">
                <p className="clr-txt products-content">Ready-to-deploy applications that
                  connect, enhance, and empower</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="products-expertise-cards-parent">
          <div className="products-expertise-cards-flex">
            <div className="products-expertise-cards-column">
              <div className="products-expertise-card-icon">
                <img alt="Pearl Communication" className="products-expertise-card-icon-img" src={PearlProductImage} />
              </div>
              <div className="products-expertise-card-title">
                <h3 className="clr-txt">PEARL COMMUNICATION</h3>
              </div>
              <div className="products-expertise-card-details">
                <p className="clr-txt">
                  Highly scalable, reliable, and configurable
                  B2B data interchange with EDI and
                  non-EDI trading partners
                </p>
                <p className="clr-txt">
                  Can transmit data between large
                  enterprises with either VAN-based or
                  direct-to-partner connections. It is
                  designed for rapid implementation and
                  is capable of processing thousands of
                </p>
              </div>
              <div className="products-expertise-card-btn">
                <a href="#" className="clr-txt products-expertise-card-ancher">Read More...</a>
              </div>
              <div className="products-expertise-card-brochure-btn">
                <a href="#" className="clr-txt products-expertise-card-ancher-brochure">Download Brochure</a>
              </div>
            </div>
            <div className="products-expertise-cards-column">
              <div className="products-expertise-card-icon">
                <img alt="Middleware Connector" className="products-expertise-card-icon-img" src={MiddlewareProductImage} />
              </div>
              <div className="products-expertise-card-title">
                <h3 className="clr-txt">MIDDLEWARE CONNECTOR</h3>
              </div>
              <div className="products-expertise-card-details">
                <p className="clr-txt">
                Achieve omnichannel unified commerce 
between your existing eCommerce
implementation and Microsoft Dynamics 
365 for Finance and Operations
                </p>
                <p className="clr-txt">
                Can transmit data between large
enterprises with either VAN-based or
direct-to-partner connections. It is 
designed for rapid implementation and
                </p>
              </div>
              <div className="products-expertise-card-btn">
                <a href="#" className="clr-txt products-expertise-card-ancher">Read More...</a>
              </div>
              <div className="products-expertise-card-brochure-btn">
                <a href="#" className="clr-txt products-expertise-card-ancher-brochure">Download Brochure</a>
              </div>
            </div>
            <div className="products-expertise-cards-column">
              <div className="products-expertise-card-icon">
                <img alt="Retail Analytics" className="products-expertise-card-icon-img" src={RetailProductImage} />
              </div>
              <div className="products-expertise-card-title">
                <h3 className="clr-txt">RETAIL ANALYTICS</h3>
              </div>
              <div className="products-expertise-card-details">
                <p className="clr-txt">
                A powerful BI platform for the retail
industry that offers over 2,500 built-in
metrics and hundreds of KPIs, reports,
and dashboards
                </p>
                <p className="clr-txt">
                Delivers industry-specific analytics while 
reducing the effort needed to create and 
deploy such sophisticated platforms from 
years to weeks. Its cloud-hosted enter
                </p>
              </div>
              <div className="products-expertise-card-btn">
                <a href="#" className="clr-txt products-expertise-card-ancher">Read More...</a>
              </div>
              <div className="products-expertise-card-brochure-btn">
                <a href="#" className="clr-txt products-expertise-card-ancher-brochure">Download Brochure</a>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className="container">
        <div className="products-expertise-cards-parent">
          <div className="products-expertise-cards-flex">
            <div className="products-expertise-cards-column">
              <div className="products-expertise-card-icon">
                <img alt="Haute Logic" className="products-expertise-card-icon-img" src={hauteProductImage} />
              </div>
              <div className="products-expertise-card-title">
                <h3 className="clr-txt">HAUTE LOGIC</h3>
              </div>
              <div className="products-expertise-card-details">
                <p className="clr-txt">
                A state-of-the-art suite of modular tools 
for intelligent fashio supply chain and
direct-to-consumer operations.
                </p>
                <p className="clr-txt">
                Is a true end-to-end solution powered by 
Microsoft Dynamics 365, Office 365, and 
intelligent cloud for manufacturers and 
retailers in fashion, apparel, and footwear. 
HauteLogic enables digital transformation
                </p>
              </div>
              <div className="products-expertise-card-btn">
                <a href="#" className="clr-txt products-expertise-card-ancher">Read More...</a>
              </div>
              <div className="products-expertise-card-brochure-btn">
                <a href="#" className="clr-txt products-expertise-card-ancher-brochure">Download Brochure</a>
              </div>
            </div>
            <div className="products-expertise-cards-column">
              <div className="products-expertise-card-icon">
                <img alt="SYCHCM" className="products-expertise-card-icon-img" src={SyshcmProductImage} />
              </div>
              <div className="products-expertise-card-title">
                <h3 className="clr-txt">SYSHCM</h3>
              </div>
              <div className="products-expertise-card-details">
                <p className="clr-txt">
                Manage, share, and steer the vast
capabilities of your staff, focus on your
critical talent, and support strategic HR 
processes.
                </p>
                <p className="clr-txt">
                Is a human capital management solution 
that offers organizations the tools they 
need to create a workforce that can 
become their most coveted competitive 
                </p>
              </div>
              <div className="products-expertise-card-btn">
                <a href="#" className="clr-txt products-expertise-card-ancher">Read More...</a>
              </div>
              <div className="products-expertise-card-brochure-btn">
                <a href="#" className="clr-txt products-expertise-card-ancher-brochure">Download Brochure</a>
              </div>
            </div>
            <div className="products-expertise-cards-column">
              <div className="products-expertise-card-icon">
                <img alt="TRIPS" className="products-expertise-card-icon-img" src={TripsProductImage} />
              </div>
              <div className="products-expertise-card-title">
                <h3 className="clr-txt">TRIPS</h3>
              </div>
              <div className="products-expertise-card-details">
                <p className="clr-txt">
                Your go-to solution for accurate, efficient 
travel insurance policy management.
                </p>
                <p className="clr-txt">
                Is a cloud-based insurance solution
designed to simplify the management
of international and domestic travel
insurance and travel for treatment (T4T).
It also includes a comprehensive
description of policy and benefits to
                </p>
              </div>
              <div className="products-expertise-card-btn">
                <a href="#" className="clr-txt products-expertise-card-ancher">Read More...</a>
              </div>
              <div className="products-expertise-card-brochure-btn">
                <a href="#" className="clr-txt products-expertise-card-ancher-brochure">Download Brochure</a>
              </div>
            </div>

          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Products;
