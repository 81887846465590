import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './multicarousel.css'
import Carousel from 'react-multi-carousel';
// import aboutimg from '../../../assets/OUR-APPROACH.png'
import 'react-multi-carousel/lib/styles.css';
function Multicarousel({data}) {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <>
      <Carousel responsive={responsive} arrows={false} infinite={true} autoPlay={true} autoPlaySpeed={2000}>
      {data.map(data => (
        <img alt="Carousel Images"
          className="d-block w-50 mx-auto"
          src={"https://cms.prosouls.net/assets/services/portfolio/"+data.image}
        />
      ))}
        {/* <img
          className="d-block w-50 mx-auto"
          src={slide2}
        />
        <img
          className="d-block w-50 mx-auto"
          src={slide3}
        />
        <img
          className="d-block w-50 mx-auto"
          src={slide4}
        />
        <img
          className="d-block w-50 mx-auto"
          src={slide5}
        />
        <img
          className="d-block w-50 mx-auto"
          src={slide6}
        />
        <img
          className="d-block w-50 mx-auto"
          src={slide7}
        />
        <img
          className="d-block w-50 mx-auto"
          src={slide8}
        />
        <img
          className="d-block w-50 mx-auto"
          src={slide9}
        /> */}
      </Carousel>
    </>
  );
}

export default Multicarousel;