import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ParticlesBackground from '../global-component/particle-background/particle-background';
import './case-studies.css';
// import CardBg1 from '../../assets/case-studies/7200953_30756.png'
import CardBg2 from '../../assets/case-studies/ai-nuclear-energy-future-innovation-disruptive-technology.png'
// import CardBg3 from '../../assets/case-studies/cyber-data-server-racks-room-with-big-data-computer-center-blue-interior-hosting-storage-hardware.png'
import Footer from '../footer/footer';
function CaseStudies() {
  const [caseStudies,setCaseStudies] = useState([])

  const fetchWorkData = () => {
    fetch("https://cms.prosouls.net/api/case_studies/getalldata")
      .then(response => {
        return response.json()
      })
      .then(data => {
        setCaseStudies(data.data)
      })
  }
  useEffect(() => {
    fetchWorkData()

  }, [])

  const [backgroundImage, setBackgroundImage] = useState('');
  const changeBackgroundImage = (img) => {
   setBackgroundImage(img)
  }
  const Cardbackground1 = {
    backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)), url(${CardBg2})`,
  };
  return (
    <>
      <ParticlesBackground />
      <section className="casestudy-banner">
        <div className="container casestudy-banner-container">
          <div className='casestudy-banner-content'>
            <h5 className='clr-txt casestudy-content-subtitle'>
              CASE STUDIES
            </h5>
            <h1 className='clr-txt casestudy-content-title'>Examples of client success, powered by Prosouls</h1>
          </div>
        </div>
      </section>
      <section class="case-studies-cards-wrapper">
      {caseStudies.length > 0 && caseStudies.map(data => (
        <div class="case-studies-card-grid-space">
          <a class="case-studies-card" href={data.route} style={{ backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)), url(${'https://cms.prosouls.net/assets/casestudies/background_image/'+data.background_image})`,}}>
            <div>
              <h1>{data.title}</h1>
              <p>{data.small_description}</p>
              {/* <div class="date">6 Oct 2017</div> */}
            </div>
          </a>
        </div>
      ))}
        
      </section>
      <Footer />
    </>
  );
}

export default CaseStudies;