import "bootstrap/dist/css/bootstrap.min.css";
// import img from "../../assets/team-png.png";
import "./careers.css";
import CareerBannerImage from '../../assets/career/Career-banner.webp'
import CareerPointImage1 from '../../assets/career/career-point-1.png'
import CareerPointImage2 from '../../assets/career/career-point-2.png'
import CareerPointImage3 from '../../assets/career/career-point-3.png'
import CareerPointImage4 from '../../assets/career/career-point-4.png'
import ParticlesBackground from "../global-component/particle-background/particle-background";
import Footer from "../footer/footer";
import Button from "react-bootstrap/Button";
// import Contact from "../contact/contact";
// import Navbarr from "../navbar/navbar";
import * as React from "react";
import Accordion from "react-bootstrap/Accordion";
// import { color } from "@mui/system";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
// import Test from "../test";
function Career() {
  const [careerJob, setCareerJob] = useState([])

  const fetchUserData = () => {
    fetch("https://cms.prosouls.net/api/career_job/getalldata")
      .then(response => {
        return response.json()
      })
      .then(data => {
        setCareerJob(data.data)

      })
  }

  const [isMobile, setMobile] = useState(window.innerWidth <= 900);

  const updateMedia = () => {
    setMobile(window.innerWidth <= 900);
  };
  useEffect(() => {
    fetchUserData()

  }, [])

  const sendEmail = () => {
    window.open("mailto:career@prosouls.net?subject=SendMail&body=Description");
  };

  return (
    
    <>
    { careerJob[0] ? ( 
    <Helmet>
        <title>{careerJob[0].meta_title}</title>
        <meta
          name="description"
          content={ careerJob[0].meta_description}
        />
        <meta name="keywords" content={ careerJob[0].meta_keyword} />
      </Helmet>
    ) :(null)
}
     {isMobile ? (
      <div className="career">
        <ParticlesBackground />
        <div className="container1234">
          <div className="parent">
            <img className="img1234" src={CareerBannerImage} alt="Career"></img>
          </div>
          <div className="hello123">
            <h3 className="approach1234">
              Amazing Opportunities
              Await You!
              <p className="careerpara">
              At Prosouls, we hold the conviction that our success is propelled by the dedication of our team. We are a leading IT solutions company, dedicated to providing cutting-edge technology solutions to our clients across various industries.
              </p>
              <p className="careerpara">
              We are constantly growing and evolving, and we're looking for talented individuals like you to join our team in Karachi and be a part of our exciting journey into the world of IT jobs.
              </p>
            </h3>
          </div>
        </div>
        <div className="container career-second-module">
          <div class="career-second-module-heading eight">
            <h1 className="clr-txt">HIRING PROCESS</h1>
            <div className="application-development-manage-services-content">
              <p className="clr-txt text-center">
              Prepare to embark on your path and explore our hiring process!
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="career-cards-flex">
            <div className="career-cards-column">
              <div className="career-card-icon">
                <img className="career-card-icon-img" src={CareerPointImage1} />
              </div>
              <div className="career-card-title">
                <h3 className="clr-txt">Apply</h3>
              </div>
              <div className="career-card-details">
                <p className="clr-txt">
                Browse the available vacancies below and submit applications for roles that match your unique skill set.
                </p>
              </div>
            </div>
            <div className="career-cards-column">
              <div className="career-card-icon">
                <img className="career-card-icon-img" src={CareerPointImage2} />
              </div>
              <div className="career-card-title">
                <h3 className="clr-txt">Review</h3>
              </div>
              <div className="career-card-details">
                <p className="clr-txt">
                  Our expert recruiters review
                  your application. They will
                  screen your résumé and find
                  the best fit for you.
                </p>
              </div>
            </div>
            <div className="career-cards-column">
              <div className="career-card-icon">
                <img className="career-card-icon-img" src={CareerPointImage3} />
              </div>
              <div className="career-card-title">
                <h3 className="clr-txt">Interviews</h3>
              </div>
              <div className="career-card-details">
                <p className="clr-txt">
                Every department follows distinct criteria, commonly involving a recruiter's initial phone screening, interviews with hiring managers, and a mix of technical assessments, panel discussions, and team presentations.
                </p>
              </div>
            </div>
            <div className="career-cards-column">
              <div className="career-card-icon">
                <img className="career-card-icon-img" src={CareerPointImage4} />
              </div>
              <div className="career-card-title">
                <h3 className="clr-txt">Onboarding</h3>
              </div>
              <div className="career-card-details">
                <p className="clr-txt">
                After receiving the 'Welcome Aboard' message, our onboarding experience will refine and prepare you for your professional journey with Prosouls.
                </p>
              </div>
            </div>

          </div>
        </div>
        <div className="jobopening">
          <h3 className="approach12345">
            JOB OPENING
            <p className="job">
              Browse through our current opening and in touch now. If you don't
              find any listed opening relevent to your profile, feel free to reach
              out anyway, and tell us why you might be a good fit email at  <span onClick={sendEmail} style={{ cursor: "pointer", color: "var(--prosouls-blue)" }}><u> career@prosouls.net.</u></span>
            </p>

          </h3>
          <Accordion defaultActiveKey="0" className="dropdowns">
            {careerJob.map(data => (
              <Accordion.Item eventKey={data.id}>
                <Accordion.Header>{data.title}</Accordion.Header>
                <Accordion.Body>
                  <h2 className="headingdd">{data.title}</h2>
                  <h4 className="frontsales">Company Overview: </h4>
                  <div className="textdd" dangerouslySetInnerHTML={{ __html: " " + data.company_overview + "" }} />
                  <h4 className="frontsales">Role: </h4>
                  <div className="textdd" dangerouslySetInnerHTML={{ __html: " " + data.role + "" }} />
                  <h4 className="frontsales">Responsibilities: </h4>
                  <div className="textdd" dangerouslySetInnerHTML={{ __html: " " + data.responsibilities + "" }} />
                  <h4 className="frontsales">Requirements: </h4>
                  <div className="textdd" dangerouslySetInnerHTML={{ __html: " " + data.requirements + "" }} />


                  <h3 className="jobtype">JOB TYPE: {data.type}</h3>
                  <h3 className="jobtype1">Market Competitive Salary</h3>

                  <Button onClick={sendEmail} className="btn45" variant="outline-primary">
                    Apply now
                  </Button>
                </Accordion.Body>
              </Accordion.Item>
            ))}

          </Accordion>
        </div>
        {/* <Contact /> */}
        <Footer />
      </div>) : (
      <div className="career">
        <ParticlesBackground />
        <div className="container1234">
          <div className="parent">
            <img className="img1234" src={CareerBannerImage} alt="Career"></img>
          </div>
          <div className="hello123">
            <h3 className="approach1234">
              Amazing Opportunities
              Await You!
              <p className="careerpara">
              At Prosouls, we hold the conviction that our success is propelled by the dedication of our team. We are a leading IT solutions company, dedicated to providing cutting-edge technology solutions to our clients across various industries.
              </p>
              <p className="careerpara">
              We are constantly growing and evolving, and we're looking for talented individuals like you to join our team in Karachi and be a part of our exciting journey into the world of IT jobs.
              </p>
            </h3>
          </div>
        </div>
        <div className="container career-second-module">
          <div class="career-second-module-heading eight">
            <h1 className="clr-txt">HIRING PROCESS</h1>
            <div className="application-development-manage-services-content">
              <p className="clr-txt text-center">
              Prepare to embark on your path and explore our hiring process!
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="career-cards-flex">
            <div className="career-cards-column">
              <div className="career-card-icon">
                <img className="career-card-icon-img" src={CareerPointImage1} />
              </div>
              <div className="career-card-title">
                <h3 className="clr-txt">Apply</h3>
              </div>
              <div className="career-card-details">
                <p className="clr-txt">
                Browse the available vacancies below and submit applications for roles that match your unique skill set.
                </p>
              </div>
            </div>
            <div className="career-cards-column">
              <div className="career-card-icon">
                <img className="career-card-icon-img" src={CareerPointImage2} />
              </div>
              <div className="career-card-title">
                <h3 className="clr-txt">Review</h3>
              </div>
              <div className="career-card-details">
                <p className="clr-txt">
                  Our expert recruiters review
                  your application. They will
                  screen your resume and find
                  the best fit for you.
                </p>
              </div>
            </div>
            <div className="career-cards-column">
              <div className="career-card-icon">
                <img className="career-card-icon-img" src={CareerPointImage3} />
              </div>
              <div className="career-card-title">
                <h3 className="clr-txt">Interviews</h3>
              </div>
              <div className="career-card-details">
                <p className="clr-txt">
                Every department follows distinct criteria, commonly involving a recruiter's initial phone screening, interviews with hiring managers, and a mix of technical assessments, panel discussions, and team presentations.
                </p>
              </div>
            </div>
            <div className="career-cards-column">
              <div className="career-card-icon">
                <img className="career-card-icon-img" src={CareerPointImage4} />
              </div>
              <div className="career-card-title">
                <h3 className="clr-txt">Onboarding</h3>
              </div>
              <div className="career-card-details">
                <p className="clr-txt">
                After receiving the 'Welcome Aboard' message, our onboarding experience will refine and prepare you for your professional journey with Prosouls.
                </p>
              </div>
            </div>

          </div>
        </div>
        <div className="jobopening">
          <h3 className="approach12345">
            JOB OPENING
            <p className="job">
              Browse through our current opening and in touch now. If you don't
              find any listed opening relevent to your profile, feel free to reach
              out anyway, and tell us why you might be a good fit email at  <span onClick={sendEmail} style={{ cursor: "pointer", color: "var(--prosouls-blue)" }}><u> career@prosouls.net.</u></span>
            </p>
          </h3>
          <Accordion defaultActiveKey="0" className="dropdowns">
            {careerJob.map(data => (
              <Accordion.Item eventKey={data.id}>
                <Accordion.Header>{data.title}</Accordion.Header>
                <Accordion.Body>
                  <h2 className="headingdd">{data.title}</h2>
                  <h4 className="frontsales">Company Overview: </h4>
                  <div dangerouslySetInnerHTML={{ __html: " " + data.company_overview + "" }} />
                  <h4 className="frontsales">Role: </h4>
                  <div dangerouslySetInnerHTML={{ __html: " " + data.role + "" }} />
                  <h4 className="frontsales">Responsibilities: </h4>
                  <div dangerouslySetInnerHTML={{ __html: " " + data.responsibilities + "" }} />
                  <h4 className="frontsales">Requirements: </h4>
                  <div dangerouslySetInnerHTML={{ __html: " " + data.requirements + "" }} />


                  <h3 className="jobtype">JOB TYPE: {data.type}</h3>
                  <h3 className="jobtype1">Market Competitive Salary</h3>

                  <Button onClick={sendEmail} className="btn45" variant="outline-primary">
                    Apply now
                  </Button>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
        {/* <Contact /> */}
        <Footer />
      </div>
    )} </>
  );
}

export default Career;
