// import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import slide1 from "../../assets/services/Systems Development.webp";
import slide2 from "../../assets/services/Design and Branding.webp";
import slide3 from "../../assets/services/Packaging.webp";
import slide4 from "../../assets/services/Mobile App Development.webp";
import slide5 from "../../assets/services/Social Media Marketing.webp";
import slide6 from "../../assets/services/Architectural Visualization.webp";
import slide7 from "../../assets/services/Website Development.webp";
import "bootstrap/dist/css/bootstrap.min.css";
import "./services.css";
// import { useRef } from "react";
// import Aos from 'aos';
// import 'aos/dist/aos.css'
function Services() {
  // useEffect(()=>{
  //   Aos.init({duration:1000})
  // })
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <div className="services" id="service">
        {/* <div className='colm'>
  <div>
    <h3 className='heading' >WHAT WE BUILD <br /> servicesS AS GOOD <br /> AS IT LOOKS</h3>
    <p className='textservices' >Your online platforms need to services for your business,
      showing your audience what you can do for them. Great
      design combined with user-friendly features creates a
      compelling digital experience </p>
  </div>

</div> */}
        <div className="services-image">
          <Carousel
            responsive={responsive}
            arrows={false}
            infinite={true}
            indicators={false}
            autoPlay={true}
            interval={5000}
          >
            <Carousel.Item className="services-carousel-item-custom carousel-item-custom">
              <div className="carousel-cstm-inner-sec">
              <div className="carousel-cstm-inner-column-two">
                <img width="100%" height="100%" alt="System Development"
                  className="services-image"
                  src={slide1}
                />
              </div>
              <div className="carousel-cstm-inner-column-one">
                <h3 className="heading">
                  SYSTEMS <br/> DEVELOPMENT 
                </h3>
                <p className="textservices">
                Prosouls provides full-cycle system development services that adapt seamlessly to your project requirements and business needs. From IT strategy consulting and robust technology road maps to end-to-end development of scalable solutions, our services include the following:
                <br/>
                <ul className="py-3 cstm-service-ul">
                  <li>E-COMMERCE</li>
                  <li>CHAT-BOT AUTOMATION</li>
                  <li>INTEGRATION SERVICE</li>
                  <li>CLOUD SOLUTIONS</li>
                  <li>BUSINESS PROCESS & RULES MANAGEMENT</li>
                  <li>ENTERPRISE MODERNIZATION</li>
                  <li>ANALYTIC AND BIG DATA</li>
                  <li>DEVOPS</li>
                </ul>
                </p>
              </div>
              </div>
            </Carousel.Item>
            <Carousel.Item className="services-carousel-item-custom carousel-item-custom">
              <div className="carousel-cstm-inner-sec">
              <div className="carousel-cstm-inner-column-two">
                <img width="100%" height="100%" alt="Design and Branding"
                  className="services-image" 
                  src={slide2}
                />
              </div>
              <div className="carousel-cstm-inner-column-one">
                <h3 className="heading">
                  DESIGN & <br/> BRANDING
                </h3>
                <p className="textservices">
                Creating logos and brands necessitates both artistic ability and strategic knowledge. Our dedicated team of specialists have the creative skills to meet challenges of all sizes. Whether you are a start-up seeking a way to bring your story to life or a known brand looking to breathe new life into what you do, our expertise in creating meaningful logos and unique brand recognition will enable you and your customers to see your brand on a whole new level.
                </p>
              </div>
              </div>
            </Carousel.Item>
            <Carousel.Item className="services-carousel-item-custom carousel-item-custom">
              <div className="carousel-cstm-inner-sec">
              <div className="carousel-cstm-inner-column-two">
                <img width="100%" height="100%" alt="Product Packaging"
                  className="services-image"
                  src={slide7}
                />
              </div>
              <div className="carousel-cstm-inner-column-one">
                <h3 className="heading">
                WEBSITE <br/> DEVELOPMENT
                </h3>
                <p className="textservices">
                The first thing prospective clients and customers see is your website. It is essential that it accurately reflects your company. We create enterprise-level websites for ambitious businesses that want to achieve more by transforming aspiration into success. We understand that simply having a website isn't enough. We'll get to the heart of what your customers want with your collaboration and then align this with your business objectives to produce a solid return on investment. 
                </p>
              </div>
              </div>
            </Carousel.Item>
            <Carousel.Item className="services-carousel-item-custom carousel-item-custom">
              <div className="carousel-cstm-inner-sec">
              <div className="carousel-cstm-inner-column-two">
                <img width="100%" height="100%" alt="Product Packaging"
                  className="services-image"
                  src={slide6}
                />
              </div>
              <div className="carousel-cstm-inner-column-one">
                <h3 className="heading">
                3D ANIMATION & <br/> VISUALIZATION
                </h3>
                <p className="textservices">
                At Prosouls we create stunning 3D Visualizations for any industry. Our team has extensive experience in the 3D rendering field. We are passionate about visual art. We being professional and easy to work with, provide exceptional quality of work at a great value.
3D visualizations are unrivaled in their ability to showcase an interior long before the space has been built. This allows designers and marketers to review design concepts before presenting to decision makers and key project stakeholders. At Prosouls we work with a passion for interior design, architecture and art. Each project, regardless of its scale and purpose, is always in our good hands.

                </p>
              </div>
              </div>
            </Carousel.Item>
            <Carousel.Item className="services-carousel-item-custom carousel-item-custom">
              <div className="carousel-cstm-inner-sec">
              <div className="carousel-cstm-inner-column-two">
                <img width="100%" height="100%" alt="Product Packaging"
                  className="services-image"
                  src={slide4}
                />
              </div>
              <div className="carousel-cstm-inner-column-one">
                <h3 className="heading">
                APPLICATION <br/> DEVELOPMENT
                </h3>
                <p className="textservices">
                Prosouls provide comprehensive application design, implementation, and management services. We Initialize our application development process from ideation and concept to delivery and post-installation services, regardless of whether the App is a user or enterprise oriented solution. Perfection is not just another characteristic, but a style of thinking that we have adopted. You can be confident that when you work with us, you are partnering with a powerful ally. who can make your digital dreams a reality.
                </p>
              </div>
              </div>
            </Carousel.Item>
            <Carousel.Item className="services-carousel-item-custom carousel-item-custom">
              <div className="carousel-cstm-inner-sec">
              <div className="carousel-cstm-inner-column-two">
                <img width="100%" height="100%" alt="Product Packaging"
                  className="services-image"
                  src={slide3}
                />
              </div>
              <div className="carousel-cstm-inner-column-one">
                <h3 className="heading">
                  PRODUCT <br/> PACKAGING DESIGN
                </h3>
                <p className="textservices">
                At Prosouls, we know how to get your products noticed with eye-catching retail packaging designs, that will draw people to your offerings. You need a package design company that recognizes the importance of marrying your product's purpose to its packaging. In order to draw in people who are truly interested in buying your product, we'll come up with a design with minimal clutter, aiming to highlight the reason people should buy your product through smart packaging label design. Even if you only market your products Online, graphic design for packaging is still important, because it can assist with your branding.
                </p>
              </div>
              </div>
            </Carousel.Item>
            <Carousel.Item className="services-carousel-item-custom carousel-item-custom">
              <div className="carousel-cstm-inner-sec">
              <div className="carousel-cstm-inner-column-two">
                <img width="100%" height="100%" alt="Product Packaging"
                  className="services-image"
                  src={slide5}
                />
              </div>
              <div className="carousel-cstm-inner-column-one">
                <h3 className="heading">
                SOCIAL <br/> MEDIA MARKETING

                </h3>
                <p className="textservices">
                Start-ups and established brands can both benefit from social media marketing services. With the right social media marketing plan and campaign monitoring system, it can lead to increased search traffic, stronger SEO, healthier customer engagement and improved brand loyalty. The service we offer are:
                <br/>
Develop Brand Awareness (Followers) Build Relationships (Engagement) Increasing Brand

                </p>
              </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </>
  );
}

export default Services;
