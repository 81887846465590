import "bootstrap/dist/css/bootstrap.min.css";
import "../../careers/careers";
import { useEffect } from "react";
import * as React from "react";
import ParticlesBackground from "../../global-component/particle-background/particle-background";
import "./it-infrastructure.css";
import Footer from "../../footer/footer";
import TableImage from "../../../assets/it-infrastructure/it-infrastructure-module.png";
import Aos from "aos";
import "aos/dist/aos.css";
import GlowingButton from "../../global-component/glowing-btn/glowing-btn";
function ItInfrastructure() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  });
  return (
    <>
      <ParticlesBackground />
      <div className="it-infrastructure-expertise-banner">
        <div class="container">
          <div class="it-infrastructure-expertise-first-module">
            <h5 className="clr-text-white text-uppercase">IT INFRASTRUCTURE</h5>
            <h1 className="clr-text-white">
              End-to-end technology deployment, administration, and support
            </h1>
            <div className="it-infrastructure-expertise-first-module-btn-parent">
              {/* <a className="it-infrastructure-expertise-first-module-btn" href="">
                Talk to an Expert{" "}
                <TrendingFlatIcon className="it-infrastructure-expertise-first-module-btn-icon" />
              </a> */}
              <GlowingButton />
            </div>
          </div>
        </div>
      </div>
      <div className="it-infrastructure-expertise-second-module-parent">
        <div class="container">
          <div className="it-infrastructure-expertise-second-module-flex">
            <div className="it-infrastructure-expertise-second-module-column one">
              <div
                data-aos="slide-right"
                className="it-infrastructure-parralax-container"
              >
                <h1 className="it-infrastructure-stroke-font">information</h1>
                <h1 className="it-infrastructure-stroke-font">technology</h1>
              </div>
            </div>
            <div
              data-aos="slide-left"
              className="it-infrastructure-expertise-second-module-column solid-bg"
            >
              <div className="it-infrastructure-expertise-solid-bg-content">
                <p className="clr-txt it-infrastructure-content">
                  Information technology infrastructure, or IT infrastructure,
                  refers to the combined components needed for the operation and
                  management of enterprise IT services and IT environments.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <section class="it-infrastructure-third-module-section">
          <div class="it-infrastructure-third-module-section__item column-one">
            <h2 className="clr-txt-blue">EXCHANGE & ACTIVE DIRECTORY</h2>
            <p className="clr-txt mb-4">
              Most enterprises and SMEs depend on Microsoft business
              technologies like Windows Server and Exchange to manage users and
              resources, communicate efficiently, and keep their information
              secure. Our experts provide a complete range of services that help
              organizations streamline the administration of these core IT
              services.
            </p>
            <p className="clr-txt">
              Microsoft licensing and key management
            </p>
            <p className="clr-txt">
              Windows Server Active Directory configuration and administration
            </p>
            <p className="clr-txt">
              Identity and access management (IAM)
            </p>
            <p className="clr-txt">Certificate management</p>
            <p className="clr-txt">DNS and DHCP</p>
            <p className="clr-txt">Windows Server Update Services</p>
            <p className="clr-txt">
              Microsoft Exchange configuration, administration, migration, and
              upgrade
            </p>
            <p className="clr-txt">Skype for Business/Microsoft Teams</p>
          </div>

          <div class="it-infrastructure-third-module-section__item column-two">
            <h2 className="clr-txt-blue">NETWORK OPERATION CENTERS</h2>
            <p className="clr-txt mb-4">
              Prosouls’s vendor-certified IT experts deploy, administer,
              virtualize, and troubleshoot your datacenter’s server, storage,
              and network hardware to ensure business continuity and resiliency.
              Our help desk support personnel assist your business users with
              computer hardware, operating systems, and applications to resolve
              issues and improve office productivity. All our support procedures
              comply with international standards and best practices for IT
              management.
            </p>
            <p className="clr-txt">CID/SIP connectivity over IP</p>
            <p className="clr-txt">Audio conference hosting</p>
            <p className="clr-txt">Extension-to-extension calling</p>
            <p className="clr-txt">Direct inward dialing</p>
            <p className="clr-txt">Ring groups</p>
            <p className="clr-txt">
              UANs, toll-free numbers, and helplines
            </p>
          </div>
        </section>
      </div>
      <div className="container my-5">
        <section class="it-infrastructure-fourth-module-section">
          <div class="it-infrastructure-fourth-module-section__item column-one">
            <h2 className="clr-txt-blue">UNIFIED COMMUNICATION</h2>
            <p className="clr-txt mb-4">
              Prosouls zero downtime for voice calling, and video calling as
              well as instant messaging. Our enterprise PBX solution provides
              affordable, high-quality IP telephony to organizations that
              require worldwide 24×7 communication. Numerous international
              organizations use our highly scalable and customizable call center
              solution to manage high-volume inbound and outbound workloads. We
              have also assisted several businesses in migrating on-premises
              Skype for Business deployments to the cloud.
            </p>
            <section class="it-infrastructure-fourth-inner-module-section">
              <div class="it-infrastructure-fourth-inner-module-section__item column-one">
                <p className="clr-txt">
                  <strong>ENTERPRISE PBX</strong>
                </p>
                <p className="clr-txt">ID/SIP connectivity over IP</p>
                <p className="clr-txt">Audio conference hosting</p>
                <p className="clr-txt">Extension-to-extension calling</p>
                <p className="clr-txt">Direct inward dialing</p>
                <p className="clr-txt">Ring groups</p>
                <p className="clr-txt">
                  UANs, toll-free numbers, and helplines
                </p>
              </div>

              <div class="it-infrastructure-fourth-inner-module-section__item column-two">
                <p className="clr-txt">
                  <strong>CALL CENTER</strong>
                </p>
                <p className="clr-txt">CID/SIP connectivity over IP</p>
                <p className="clr-txt">Audio conference hosting</p>
                <p className="clr-txt">Extension-to-extension calling</p>
                <p className="clr-txt">Direct inward dialing</p>
                <p className="clr-txt">Ring groups</p>
                <p className="clr-txt">
                  UANs, toll-free numbers, and helplines
                </p>
              </div>
            </section>
          </div>
          <div class="it-infrastructure-fourth-module-section__item">
            <img
              data-aos="fade-up" alt="UNIFIED COMMUNICATION"
              data-aos-anchor-placement="top-center"
              className="it-infrastructure-table-img"
              src={TableImage}
            />
          </div>
        </section>
      </div>
      <div className="container my-5">
        <div class="it-support-eight">
          <h1 className="clr-txt">IT SUPPORT</h1>
        </div>
        <section class="it-infrastructure-fifth-module-section mt-5">
          <div class="it-infrastructure-fifth-module-section__item column-one">
            <p className="clr-txt mb-4">
              Prosouls’s vendor-certified IT experts deploy, administer,
              virtualize, and troubleshoot your datacenter’s server, storage,
              and network hardware to ensure business continuity and resiliency.
              Our help desk support personnel assist your business users with
              computer hardware, operating systems, and applications to resolve
              issues and improve office productivity. All our support procedures
              comply with international standards and best practices for IT
              management.
            </p>
            <p className="clr-txt">
              <strong>DATACENTER MANAGEMENT</strong>
            </p>
            <p className="clr-txt mng-line-height">
              Compute, storage, and network hardware deployment and
              administration Linux & Windows server installation, configuration,
              and troubleshooting Virtualization using VMware and Microsoft
              Hyper-V Monitoring of all running services using Nagios
            </p>
          </div>

          <div class="it-infrastructure-fifth-module-section__item column-two">
            <p className="clr-txt">
              <strong>HELP DESK SUPPORT</strong>
            </p>
            <p className="clr-txt mb-4 mng-line-height">
              Device driver and application Installation and troubleshooting{" "}
              <br />
              OS and hardware troubleshooting and upgrades <br />
              Antivirus installation, configuration, and administration <br />
              Audio and video conferencing support
            </p>
            <p className="clr-txt ">
              <strong>BACKUP & RESTORE</strong>
            </p>
            <p className="clr-txt mng-line-height m-0">
              Scheduled backup of business data <br />
              Bare-metal backup of critical servers <br />
              Regularly scheduled backup testing
            </p>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default ItInfrastructure;
