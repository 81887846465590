import "bootstrap/dist/css/bootstrap.min.css";
import "./client.css";
import { useState , useEffect } from "react";
import client1 from "../../assets/clients-logo/clientlogo1.webp";
import client3 from "../../assets/clients-logo/clientlogo2.webp";
import client2 from "../../assets/clients-logo/clientlogo3.webp";
import client5 from "../../assets/clients-logo/clientlogo4.webp";
import client4 from "../../assets/clients-logo/clientlogo5.webp";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";
import Aos from 'aos';
import 'aos/dist/aos.css'
function Client() {
  useEffect(()=>{
    Aos.init({duration:500})
  })
  const [isMobile, setMobile] = useState(window.innerWidth <= 900);
  const updateMedia = () => {
    setMobile(window.innerWidth <= 900);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  // let heading = "IMPRESSIVE \n RESULT \n FOR CLIENTS LIKE YOU ! ";
  return (
    <> 
    {isMobile ? (
    <div id="client">
      <div className="client">
        <div className="headingcontainer">
          <h1 className="clientheading">
            IMPRESSIVE RESULT FOR CLIENTS LIKE YOU!
          </h1>
        </div>
        <Carousel style={{ backgroundColor: "white" }}>
          <Carousel.Item interval={3000}>
            <img alt="Clients" width="100%" height="100%" className="d-block w-75 mx-auto " src={client1} />
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <img alt="Clients" width="100%" height="100%" className="d-block w-75 mx-auto" src={client2} />
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <img alt="Clients" width="100%" height="100%" className="d-block w-75 mx-auto" src={client3} />
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <img alt="Clients" width="100%" height="100%" className="d-block w-75 mx-auto" src={client4} />
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <img alt="Clients" width="100%" height="100%" className="d-block w-75 mx-auto" src={client5} />
          </Carousel.Item>
        </Carousel>
        <div>
          <p  className="text">
            We work with a diverse range of clients in both the product and
            service industries. Our clients range from traditional business
            chains to apparels, and from technology firms to trading firms. Our
            expertise enables us to fulfill all types of client's needs and
            provide dedicated and personalized service to each.
          </p>
        </div>
      </div>
    </div>
    ): (
      <div id="client">
      <div className="client">
        <div className="headingcontainer">
          <h1 data-aos="slide-up"  className="clientheading">
            IMPRESSIVE RESULT FOR CLIENTS LIKE YOU!
          </h1>
        </div>
        <Carousel style={{ backgroundColor: "white" }}>
          <Carousel.Item interval={1000}>
            <img alt="Clients" width="100%" height="100%" className="d-block w-75 mx-auto " src={client1} />
          </Carousel.Item>
          <Carousel.Item interval={1000}>
            <img alt="Clients" width="100%" height="100%" className="d-block w-75 mx-auto" src={client2} />
          </Carousel.Item>
          <Carousel.Item interval={1000}>
            <img alt="Clients" width="100%" height="100%" className="d-block w-75 mx-auto" src={client3} />
          </Carousel.Item>
          <Carousel.Item interval={1000}>
            <img alt="Clients" width="100%" height="100%" className="d-block w-75 mx-auto" src={client4} />
          </Carousel.Item>
          <Carousel.Item interval={1000}>
            <img alt="Clients" width="100%" height="100%" className="d-block w-75 mx-auto" src={client5} />
          </Carousel.Item>
        </Carousel>
        <div>
          <p data-aos="slide-down"  className="text">
            We work with a diverse range of clients in both the product and
            service industries. Our clients range from traditional business
            chains to apparels, and from technology firms to trading firms. Our
            expertise enables us to fulfill all types of client's needs and
            provide dedicated and personalized service to each.
          </p>
        </div>
      </div>
    </div>
    )}
    </>
  );
}

export default Client;
