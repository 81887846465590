import "bootstrap/dist/css/bootstrap.min.css";
import "../../careers/careers";
// import Navbarr from "../../navbar/navbar";
import { useState, useEffect } from "react";
import * as React from "react";
import ParticlesBackground from "../../global-component/particle-background/particle-background";
import Stylesheet from "./cloud-team.css";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import Footer from "../../footer/footer";
import GlowingButton from "../../global-component/glowing-btn/glowing-btn";
import CloudTeamstep1 from "../../../assets/cloud-team/cloud-team-step1.png";
import CloudTeamstep2 from "../../../assets/cloud-team/cloud-team-step2.png";
import CloudTeamstep3 from "../../../assets/cloud-team/cloud-team-step3.png";
import CloudTeamstep4 from "../../../assets/cloud-team/cloud-team-step4.png";
import FullstackImage1 from "../../../assets/cloud-team/fullstack/cloud-team-full-stack-1.png";
import FullstackImage2 from "../../../assets/cloud-team/fullstack/cloud-team-full-stack-2.png";
import FullstackImage3 from "../../../assets/cloud-team/fullstack/cloud-team-full-stack-3.png";
import FullstackImage4 from "../../../assets/cloud-team/fullstack/cloud-team-full-stack-4.png";
import FullstackImage5 from "../../../assets/cloud-team/fullstack/cloud-team-full-stack-5.png";
import FullstackImage6 from "../../../assets/cloud-team/fullstack/cloud-team-full-stack-6.png";
import FullstackImage7 from "../../../assets/cloud-team/fullstack/cloud-team-full-stack-7.png";
import FullstackImage8 from "../../../assets/cloud-team/fullstack/cloud-team-full-stack-8.png";
import FullstackImage9 from "../../../assets/cloud-team/fullstack/cloud-team-full-stack-9.png";
import FullstackImage10 from "../../../assets/cloud-team/fullstack/cloud-team-full-stack-10.png";
import FullstackImage11 from "../../../assets/cloud-team/fullstack/cloud-team-full-stack-11.png";
import FullstackImage12 from "../../../assets/cloud-team/fullstack/cloud-team-full-stack-12.png";
import CloudEngineeringImage1 from "../../../assets/cloud-team/cloud-engineering/cloud-engineering-1.png";
import CloudEngineeringImage2 from "../../../assets/cloud-team/cloud-engineering/cloud-engineering-2.png";
import CloudEngineeringImage3 from "../../../assets/cloud-team/cloud-engineering/cloud-engineering-3.png";
import CloudEngineeringImage4 from "../../../assets/cloud-team/cloud-engineering/cloud-engineering-4.png";
import CloudEngineeringImage5 from "../../../assets/cloud-team/cloud-engineering/cloud-engineering-5.png";
import DevOpsImage1 from "../../../assets/cloud-team/devops/devops-1.png";
import DevOpsImage2 from "../../../assets/cloud-team/devops/devops-2.png";
import DevOpsImage3 from "../../../assets/cloud-team/devops/devops-3.png";
import DevOpsImage4 from "../../../assets/cloud-team/devops/devops-4.png";
import DevOpsImage5 from "../../../assets/cloud-team/devops/devops-5.png";
import DevOpsImage6 from "../../../assets/cloud-team/devops/devops-6.png";
import DevOpsImage7 from "../../../assets/cloud-team/devops/devops-7.png";
import DevOpsImage8 from "../../../assets/cloud-team/devops/devops-8.png";
import DataAnalyticImage1 from "../../../assets/cloud-team/datanalytics-andbi/1.png";
import DataAnalyticImage2 from "../../../assets/cloud-team/datanalytics-andbi/2.png";
import DataAnalyticImage3 from "../../../assets/cloud-team/datanalytics-andbi/3.png";
import DataAnalyticImage4 from "../../../assets/cloud-team/datanalytics-andbi/4.png";
import DataAnalyticImage5 from "../../../assets/cloud-team/datanalytics-andbi/5.png";
import DatabaseImage1 from "../../../assets/cloud-team/database/1.png";
import DatabaseImage2 from "../../../assets/cloud-team/database/2.png";
import DatabaseImage3 from "../../../assets/cloud-team/database/3.png";
import DatabaseImage4 from "../../../assets/cloud-team/database/4.png";
import DatabaseImage5 from "../../../assets/cloud-team/database/5.png";
import MobilityImage1 from "../../../assets/cloud-team/mobility/1.png";
import MobilityImage2 from "../../../assets/cloud-team/mobility/2.png";
import MobilityImage3 from "../../../assets/cloud-team/mobility/3.png";
import MobilityImage4 from "../../../assets/cloud-team/mobility/4.png";
import MobilityImage5 from "../../../assets/cloud-team/mobility/5.png";
import ItSupportandSecurityImage1 from "../../../assets/cloud-team/it-support-security/1.png";
import ItSupportandSecurityImage2 from "../../../assets/cloud-team/it-support-security/2.png";
import ItSupportandSecurityImage3 from "../../../assets/cloud-team/it-support-security/3.png";
import ItSupportandSecurityImage4 from "../../../assets/cloud-team/it-support-security/4.png";
import ItSupportandSecurityImage5 from "../../../assets/cloud-team/it-support-security/5.png";
import ItSupportandSecurityImage6 from "../../../assets/cloud-team/it-support-security/6.png";
import ItSupportandSecurityImage7 from "../../../assets/cloud-team/it-support-security/7.png";
import ItSupportandSecurityImage8 from "../../../assets/cloud-team/it-support-security/8.png";
import ItSupportandSecurityImage9 from "../../../assets/cloud-team/it-support-security/9.png";
import AutomationImage1 from "../../../assets/cloud-team/automation/1.png";
import AutomationImage2 from "../../../assets/cloud-team/automation/2.png";
import AutomationImage3 from "../../../assets/cloud-team/automation/3.png";
import AutomationImage4 from "../../../assets/cloud-team/automation/4.png";
import QAImage1 from "../../../assets/cloud-team/q-a-services/1.png";
import QAImage2 from "../../../assets/cloud-team/q-a-services/2.png";
import QAImage3 from "../../../assets/cloud-team/q-a-services/3.png";
import QAImage4 from "../../../assets/cloud-team/q-a-services/4.png";
import QAImage5 from "../../../assets/cloud-team/q-a-services/5.png";
import QAImage6 from "../../../assets/cloud-team/q-a-services/6.png";
import QAImage7 from "../../../assets/cloud-team/q-a-services/7.png";
import UIUXImage1 from "../../../assets/cloud-team/ui-ux/1.png";
import UIUXImage2 from "../../../assets/cloud-team/ui-ux/2.png";
import UIUXImage3 from "../../../assets/cloud-team/ui-ux/3.png";
import UIUXImage4 from "../../../assets/cloud-team/ui-ux/4.png";
import Aos from 'aos';
import 'aos/dist/aos.css'
function CloudTeam() {
  useEffect(() => {
    Aos.init({ duration: 2000 })
  })
  return (
    <>
      <ParticlesBackground />
      <div className="cloud-team-expertise-banner">
        <div class="container">
          <div class="cloud-team-expertise-first-module">
            <h5 className="clr-text-white text-uppercase">
              CLOUD TEAM
            </h5>
            <h1 className="clr-text-white">
              Hire trained professionals to
              enhance your team instantly
            </h1>
            <div className="cloud-team-expertise-first-module-btn-parent">
              <GlowingButton />
            </div>
          </div>
        </div>
      </div>
      <div className="cloud-team-expertise-second-module-parent">
        <div class="container">
          <div className="cloud-team-expertise-second-module-flex">
            <div className="cloud-team-expertise-second-module-column one">
              <div data-aos="slide-right" className="cloud-team-parralax-container">
                <h1 className="cloud-team-stroke-font">Cloud</h1>
                <h1 className="cloud-team-stroke-font">Technology</h1>
              </div>
            </div>
            <div data-aos="slide-left" className="cloud-team-expertise-second-module-column solid-bg">
              <div className="cloud-team-expertise-solid-bg-content">
                <p className="clr-txt cloud-team-content">Cloud team allows you to hire on-site or offshore
                  technical resources without being constrained by
                  distance or international borders. Our skilled
                  technical team possesses a diverse skill set that
                  provides our clients the flexibility they need for their
                  short-term and ongoing projects.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <p className="clr-txt text-center">
          Using Prosouls’s technical resources saves you from the cost and effort associated with recruiting and retaining specialized in-house team members. Clients
          that choose Prosouls’s offshore cloud team services also benefit from our presence in multiple time zones, which allows us to provide skilled resources when
          you need them, including 24×7 support.
        </p>
      </div>
      <div className="container my-5">
        <div class="cloud-team-eight">
          <h1 className="clr-txt">BENEFITS</h1>
        </div>
      </div>
      <div className="container">
        <div className="career-cards-flex">
          <div className="career-cards-column">
            <div className="career-card-icon">
              <img className="career-card-icon-img" src={CloudTeamstep1} />
            </div>
            <div className="career-card-title">
              <h3 className="clr-txt">High Productivity</h3>
            </div>
            <div className="career-card-details">
              <p className="clr-txt">
                Developers and other technical
                resources are assigned to one project
                at a time, which revents inefficiency
                caused by switching between multiple
                tasks. They remain focused and
                motivated throughout their
                assignment.
              </p>
            </div>
          </div>
          <div className="career-cards-column">
            <div className="career-card-icon">
              <img className="career-card-icon-img" src={CloudTeamstep2} />
            </div>
            <div className="career-card-title">
              <h3 className="clr-txt">Cost-Efficiency</h3>
            </div>
            <div className="career-card-details">
              <p className="clr-txt">
                Assembling a cloud team eliminates
                typical administrative & organizational
                expenses like screening, interviewing,
                onboarding, office space, and
                equipment. We take care of these
                details so you can focus on business
                success.
              </p>
            </div>
          </div>
          <div className="career-cards-column">
            <div className="career-card-icon">
              <img className="career-card-icon-img" src={CloudTeamstep3} />
            </div>
            <div className="career-card-title">
              <h3 className="clr-txt">Cost-Efficiency</h3>
            </div>
            <div className="career-card-details">
              <p className="clr-txt">
                Cloud teams know no global
                boundaries. You can recruit multiple
                UX designers and front-end,
                back- end, or full-stack developers,
                even if none are available locally. We
                help you address skill gaps and get
                your projects on track.
              </p>
            </div>
          </div>
          <div className="career-cards-column">
            <div className="career-card-icon">
              <img className="career-card-icon-img" src={CloudTeamstep4} />
            </div>
            <div className="career-card-title">
              <h3 className="clr-txt">More Control</h3>
            </div>
            <div className="career-card-details">
              <p className="clr-txt">
                Hiring a cloud team isn’t the same as
                outsourcing; you have the freedom
                to manage your resources closely.
                You retain complete control over
                who is working on the project and
                what their specific project
                responsibilities are.
              </p>
            </div>
          </div>

        </div>
      </div>
      <div className="container my-5">
        <section class="cloud-team-fourth-module-section">
          <div class="cloud-team-fourth-module-section__item column-one">
            <h1 className="clr-txt mb-5">Our Process</h1>
            <div className="cloud-team-bullet-section">
            <div className="cloud-team-bullet-parent">
              <div className="cloud-team-bullet-circle">
                <h1 className="clr-txt">1</h1>
              </div>
              <div className="cloud-team-bullet-text">
                <p className="clr-txt">Share your project requirements</p>
              </div>
            </div>
            <div className="cloud-team-bullet-parent">
              <div className="cloud-team-bullet-circle">
                <h1 className="clr-txt">2</h1>
              </div>
              <div className="cloud-team-bullet-text">
                <p className="clr-txt">Interview potential candidates</p>
              </div>
            </div>
            <div className="cloud-team-bullet-parent">
              <div className="cloud-team-bullet-circle">
                <h1 className="clr-txt">3</h1>
              </div>
              <div className="cloud-team-bullet-text">
                <p className="clr-txt">Shortlist and hire the best ones</p>
              </div>
            </div>
            <div className="cloud-team-bullet-parent">
              <div className="cloud-team-bullet-circle">
                <h1 className="clr-txt">4</h1>
              </div>
              <div className="cloud-team-bullet-text">
                <p className="clr-txt">Get to work!</p>
              </div>
            </div>
            </div>
          </div>
          <div class="cloud-team-fourth-module-section__item">
           <div className="cluod-team-tech-parent">
            <div className="cluod-team-tech-name">
              <p className="clr-txt">Full Stack Application Development</p>
              <p className="clr-txt">Cloud Engineering and Integration</p>
              <p className="clr-txt">DevOps</p>
              <p className="clr-txt">Data Analytics & BI</p>
              <p className="clr-txt">Database</p>
              <p className="clr-txt">Mobility</p>
              <p className="clr-txt">Collaboration</p>
              <p className="clr-txt">IT Support & Security</p>
              <p className="clr-txt">Automation</p>
              <p className="clr-txt">QA Services</p>
              <p className="clr-txt">UX/UI</p>
            </div>
           </div>
          </div>
        </section>
      </div>
      <div className="container cloud-team-our-clinets">
        <div className="cloud-team-techs-title">
          <h1 className="clr-txt text-center text-uppercase mb-5">Full Stack Application Development</h1>
          <div class="cloud-team-tech-container">
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={FullstackImage1} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={FullstackImage2} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={FullstackImage3} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={FullstackImage4} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={FullstackImage5} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={FullstackImage6} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={FullstackImage7} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={FullstackImage8} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={FullstackImage9} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={FullstackImage10} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={FullstackImage11} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={FullstackImage12} />
            </div>
          </div>
        </div>
      </div>
      <div className="container cloud-team-our-clinets">
        <div className="cloud-team-techs-title">
          <h1 className="clr-txt text-center text-uppercase mb-5">Cloud Engineering and Integration</h1>
          <div class="cloud-team-tech-container">
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={CloudEngineeringImage1} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={CloudEngineeringImage2} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={CloudEngineeringImage3} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={CloudEngineeringImage4} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={CloudEngineeringImage5} />
            </div>
          </div>
        </div>
      </div>
      <div className="container cloud-team-our-clinets">
        <div className="cloud-team-techs-title">
          <h1 className="clr-txt text-center text-uppercase mb-5">DevOps</h1>
          <div class="cloud-team-tech-container">
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={DevOpsImage1} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={DevOpsImage2} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={DevOpsImage3} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={DevOpsImage4} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={DevOpsImage5} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={DevOpsImage6} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={DevOpsImage7} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={DevOpsImage8} />
            </div>
          </div>
        </div>
      </div>
      <div className="container cloud-team-our-clinets">
        <div className="cloud-team-techs-title">
          <h1 className="clr-txt text-center text-uppercase mb-5">Data Analytics & BI</h1>
          <div class="cloud-team-tech-container">
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={DataAnalyticImage1} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={DataAnalyticImage2} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={DataAnalyticImage3} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={DataAnalyticImage4} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={DataAnalyticImage5} />
            </div>
          </div>
        </div>
      </div>
      <div className="container cloud-team-our-clinets">
        <div className="cloud-team-techs-title">
          <h1 className="clr-txt text-center text-uppercase mb-5">Database</h1>
          <div class="cloud-team-tech-container">
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={DatabaseImage1} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={DatabaseImage2} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={DatabaseImage3} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={DatabaseImage4} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={DatabaseImage5} />
            </div>
          </div>
        </div>
      </div>
      <div className="container cloud-team-our-clinets">
        <div className="cloud-team-techs-title">
          <h1 className="clr-txt text-center text-uppercase mb-5">Mobility</h1>
          <div class="cloud-team-tech-container">
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={MobilityImage1} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={MobilityImage2} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={MobilityImage3} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={MobilityImage4} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={MobilityImage5} />
            </div>
          </div>
        </div>
      </div>
      <div className="container cloud-team-our-clinets">
        <div className="cloud-team-techs-title">
          <h1 className="clr-txt text-center text-uppercase mb-5">IT Support & Security</h1>
          <div class="cloud-team-tech-container">
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={ItSupportandSecurityImage1} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={ItSupportandSecurityImage2} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={ItSupportandSecurityImage3} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={ItSupportandSecurityImage4} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={ItSupportandSecurityImage5} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={ItSupportandSecurityImage6} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={ItSupportandSecurityImage7} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={ItSupportandSecurityImage8} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={ItSupportandSecurityImage9} />
            </div>
          </div>
        </div>
      </div>
      <div className="container cloud-team-our-clinets">
        <div className="cloud-team-techs-title">
          <h1 className="clr-txt text-center text-uppercase mb-5">Automation</h1>
          <div class="cloud-team-tech-container">
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={AutomationImage1} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={AutomationImage2} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={AutomationImage3} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={AutomationImage4} />
            </div>
          </div>
        </div>
      </div>
      <div className="container cloud-team-our-clinets">
        <div className="cloud-team-techs-title">
          <h1 className="clr-txt text-center text-uppercase mb-5">QA Services</h1>
          <div class="cloud-team-tech-container">
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={QAImage1} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={QAImage2} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={QAImage3} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={QAImage4} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={QAImage5} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={QAImage6} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={QAImage7} />
            </div>
          </div>
        </div>
      </div>
      <div className="container cloud-team-our-clinets">
        <div className="cloud-team-techs-title">
          <h1 className="clr-txt text-center text-uppercase mb-5">UX/UI</h1>
          <div class="cloud-team-tech-container">
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={UIUXImage1} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={UIUXImage2} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={UIUXImage3} />
            </div>
            <div class="cloud-team-tech-item">
              <img className="cloud-team-tech-img" src={UIUXImage4} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CloudTeam;
