import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

function ParticlesBackground() {
    const particlesInit = async (main) => {
        console.log(main);
        await loadFull(main);
    };

    return (
        <>
            <Particles id="tsparticles"
                init={particlesInit}
                params={{
                    "particles": {
                        "number": {
                            "value": 40,
                            "density": {
                                "enable": false,
                                "value_area": 10000
                            }
                        },
                        "color": {
                            "value": "#066a92"
                        },
                        "shape": {
                            "type": "edge",
                            "stroke": {
                                "width": 0,
                                "color": "#066a92"
                            },
                            "polygon": {
                                "nb_sides": 7
                            },
                            "image": {
                                "src": "img/github.svg",
                                "width": 100,
                                "height": 100
                            }
                        },
                        "opacity": {
                            "value": 0.6392095897221408,
                            "random": false,
                            "anim": {
                                "enable": false,
                                "speed": 1,
                                "opacity_min": 0.1,
                                "sync": false
                            }
                        },
                        "size": {
                            "value": 3,
                            "random": true,
                            "anim": {
                                "enable": false,
                                "speed": 40,
                                "size_min": 0.1,
                                "sync": false
                            }
                        },
                        "line_linked": {
                            "enable": true,
                            "distance": 224.4776885211732,
                            "color": "#0882ab",
                            "opacity": 0.4,
                            "width": 0
                        },
                        "move": {
                            "enable": true,
                            "speed": 1,
                            "direction": "none",
                            "random": false,
                            "straight": false,
                            "out_mode": "out",
                            "bounce": false,
                            "attract": {
                                "enable": false,
                                "rotateX": 561.194221302933,
                                "rotateY": 1200
                            }
                        }
                    },
                    "interactivity": {
                        "detect_on": "window",
                        "events": {
                            "onhover": {
                                "enable": true,
                                "mode": "grab"
                            },
                            "onclick": {
                                "enable": true,
                                "mode": "push"
                            },
                            "resize": true
                        },
                        "modes": {
                            "grab": {
                                "distance": 107.8921078921079,
                                "line_linked": {
                                    "opacity": 1
                                }
                            },
                            "bubble": {
                                "distance": 191.80819180819182,
                                "size": 0,
                                "duration": 0.4795204795204795,
                                "opacity": 0,
                                "speed": 3
                            },
                            "repulse": {
                                "distance": 47.952047952047955,
                                "duration": 0.4
                            },
                            "push": {
                                "particles_nb": 4
                            },
                            "remove": {
                                "particles_nb": 2
                            }
                        }
                    },
                    "retina_detect": false,
                    "background": {
                        // "color": "#021928",
                        "image": "",
                        "position": "50% 50%",
                        "repeat": "no-repeat",
                        "size": "cover"
                    }
                }} >
            </Particles>
        </>
    );
}

export default ParticlesBackground;