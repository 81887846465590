import './glowing-btn.css'
function GlowingButton() {
  return (
    <>
    <form>
      <button formaction="#contact" class='glowing-btn'><span class='glowing-txt'><span class='faulty-letter'></span><span class='faulty-letter'>TALK TO AN EXPERT</span></span> </button>
      </form>
    </>
  );
}

export default GlowingButton;