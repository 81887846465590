// import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ParticlesBackground from '../global-component/particle-background/particle-background';
import './case-study.css';
import Footer from '../footer/footer';
import { Helmet } from 'react-helmet';
function CaseStudyView(props) {
  console.log(props.metaDescription)
  console.log(props.metaKeyword)
  return (
    <>
    <Helmet>
        <title>{props.metaTitle}</title>
        <meta name="description" content={ props.metaDescription} />
        <meta name="keywords" content={props.metaKeyword} />
      </Helmet>
      <ParticlesBackground />
      
      <section className="case-study-banner">
        <div className="container case-study-banner-container">
          <div className='case-study-banner-content'>
            <h5 className='clr-txt case-study-content-subtitle'>
              CASE STUDY
            </h5>
            <h1 className='clr-txt case-study-content-title'>{props.title}</h1>
          </div>
        </div>
      </section>
      <div className='container case-study-module-one'>
        <div className='case-study-module-one-flex'>
          <div className='case-study-module-one-column column-one'>
            <div className='case-study-desciption' dangerouslySetInnerHTML={{ __html: " " + props.description + "" }} />
          </div>
          <div className='case-study-module-one-column column-two'>
            <div className="container mb-3">
              <div className='case-study-form-parent'>
                <div class="login-box">
                  <div class="case-study-form-title mb-5">
                    <h1 className="clr-txt">Download Case Study</h1>
                  </div>
                  <form>
                    <div class="user-box">
                      <input type="text" name="" required="" />
                      <label>Name (required)</label>
                    </div>
                    <div class="user-box">
                      <input type="email" name="" required="" />
                      <label>Email (required)</label>
                    </div>
                    <div class="user-box">
                      <input type="text" name="" />
                      <label>Company</label>
                    </div>
                    <a href="#">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      Download
                    </a>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CaseStudyView;