import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import Footer from '../../footer/footer'
import Multicarousel from '../../global-component/multi-carousel/multi-carousel';
import Testimonials from '../../global-component/testimonials-component/testimonial-carousel'
import ParticlesBackground from '../../global-component/particle-background/particle-background';
import { Helmet } from 'react-helmet';
import { useService } from '../../../context/Service';
function MobileAppDevelopment() {
   const {mobileAppService,mobileAppbackgroundImage}=useService();
console.log(mobileAppService)
    const [service] = mobileAppService;

   const [backgroundImage, setBackgroundImage] = mobileAppbackgroundImage;
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    const mobile_development_carousel = {
        backgroundImage: `url(${backgroundImage})`,
        width: "100%",
        backgroundPosition: "right top",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        display: "flex",
        padding: "13% 0",
        justifyContent: "flex-start"
      };

    return (
        <>

        <Helmet>
        <title>{service.length > 0 && service[0].meta_title}</title>
        <meta name="description" content={service.length > 0 && service[0].meta_description} />
        <meta name="keywords" content={service.length > 0 && service[0].meta_keyword} />
      </Helmet>
            <ParticlesBackground />
         
            
            <Carousel fade={true} indicators={false} style={mobile_development_carousel} className='carousel-parent-container' activeIndex={index} onSelect={handleSelect} >
          
                <Carousel.Item className='carousel-item-custom'>
                    <div className='text-container-parent'>
                        <h3 className="d-block w-50 clr-txt">{service.length > 0 && service[0].small_description}</h3>
                        <h1 className="d-block w-50 clr-txt">{service.length > 0 && service[0].title}</h1>
                        <p className=' d-block w-50 clr-txt'>{service.length > 0 && service[0].description}</p>
                    </div>
                    {/* <div className='d-flex justify-content-start w-75 clr-text-white mob-text'>
                        <img className='clr-text-white' width={400} src={Mobimg} />
                        <div className='img-box-text'>
                            <h3 className='clr-text-white'>START TO <br />
                                SOMETHING<br />
                                GREAT</h3>
                            <p>Android / iOS App Development <br />
                                Develop visually pleasing apps <br />
                                optimized to swiftly grow your <br />
                                global business revenue</p>
                        </div>
                        <div className='get-qoute-parent'>
                            <a href='#contact' className='get-in-touch-btn'>GET A QOUTE</a>
                        </div>
                    </div> */}
                </Carousel.Item>
                
            </Carousel>
    
            <div className='app-scnd-module'>
                <h1 className='clr-txt scnd-module-heading'>
                    Have A Glimpse Of Some <br />Of Our Best Work!
                </h1>
                <div className='bg-blue-container'>
 { service.length > 0 &&    
 <Multicarousel data={service[0].portfolio} />
}
                </div>
                <Container>
                    <div className='row justify-content-between my-5'>
                        <div className='col-lg-2 col-md-6'>
                            <p className='clr-txt'>
                                Experience<br />
                                15+ Years of Experience.
                            </p>
                        </div>
                        <div className='col-lg-2 col-md-6'>
                            <p className='clr-txt'>
                                Technology Experts <br />
                                475+ Technology Experts.
                            </p>
                        </div>
                        <div className='col-lg-4 col-md-6'>
                            <p className='clr-txt text-uppercase'>
                            CORRESPOND MOBILITY SOLUTION WITH TOP-RATED MOBILE APP DEVELOPMENT WORLDWIDE. PROMOTE YOUR MILLION-DOLLAR IDEA WITH US
                            </p>
                        </div>
                    </div>
                </Container>
            </div>
 
            <Container className='app-third-module'>
                <div className='app-third-head-parent'>
                    <h1 className='clr-txt third-module-heading'>
                        Our clients simply<br />Love Our Work
                    </h1>
                    <p className='clr-txt mb-5'>
                    To provide your business with customized and unique mobile app development services at reasonable prices.
                    </p>
                </div>

                <Container>
                    <Testimonials />
                    <div className='row justify-content-center my-5'>
                        <div className='col-12'>
                            <p className='clr-txt text-center mt-5'>
                                Milestone Wise Quality Delivery Charges | Zero Cost Maintenance Guaranteed 2.5 Years | Real Time 24x7 Tracking and Support
                                200+ Startups 80+ SMEs 50+ Enterprises App Delivered | Google & Apple Certified App Development Company
                            </p>
                        </div>
                    </div>
                </Container>
            </Container>
           
       
            <Footer />
            
            {/* ))} */}
        </>
    );
}

export default MobileAppDevelopment;