import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./work.css";
import Carousel from "react-bootstrap/Carousel";
// import aboutimg from '../../assets/OUR-APPROACH.png'
import "bootstrap/dist/css/bootstrap.min.css";
function Work() {
  const [work, setWork] = useState([]);

  const fetchWorkData = () => {
    fetch("https://cms.prosouls.net/api/work/getalldata")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setWork(data.data);
      });
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  useEffect(() => {
    fetchWorkData();
  }, []);

  return (
    <>
      <div className="work" id="work">
        <div className="colm">
          <div>
            <h3 className="heading">
              WHAT WE BUILD <br /> WORKS AS GOOD <br /> AS IT LOOKS
            </h3>
            <p className="textwork">
              Your business's online platforms should effectively demonstrate
              your value to the audience. By integrating exceptional design and
              user-friendly functionalities, we craft a captivating digital
              journey that leaves a lasting impact.
            </p>
          </div>
        </div>
        <div className="work-image">
          {work.length > 0 && (
            <Carousel
              responsive={responsive}
              indicators={false}
              arrows={true}
              infinite={true}
              autoPlay={true}
              interval={5000}
            >
              {work.map((data) => (
                <Carousel.Item className="carousel-item-custom">
                  <img
                    alt="Work"
                    className="d-block w-50 mx-auto image"
                    width="100%"
                    height="100%"
                    src={"https://cms.prosouls.net/assets/works/" + data.image}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          )}
        </div>
      </div>
    </>
  );
}

export default Work;
