import "bootstrap/dist/css/bootstrap.min.css";
import "../../careers/careers";
// import Button from "react-bootstrap/Button";
// import Navbarr from "../../navbar/navbar";
import * as React from "react";
// import { color } from "@mui/system";
import { useEffect } from "react";
import ParticlesBackground from "../../global-component/particle-background/particle-background";
import "./user-experience.css";
// import { ViewColumn } from "@mui/icons-material";
// import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import Footer from "../../footer/footer";
// import SpeedIcon from "@mui/icons-material/Speed";
// import PriceChangeIcon from '@mui/icons-material/PriceChange';
// import AdsClickIcon from '@mui/icons-material/AdsClick';
// import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import Aos from 'aos';
import 'aos/dist/aos.css'
import GlowingButton from "../../global-component/glowing-btn/glowing-btn";
function UserExperience() {
  useEffect(()=>{
    Aos.init({duration:2000})
  })
  return (
    <>
      <ParticlesBackground />
      <div className="user-experince-expertise-banner">
        <div class="container">
          <div class="user-experince-expertise-first-module">
            <h5 className="clr-text-white text-uppercase">
              USER EXPERIENCE
            </h5>
            <h1 className="clr-text-white">
              We’re a dynamic team
              that creates & embraces
              change
            </h1>
            <div className="user-experince-expertise-first-module-btn-parent">
              {/* <a className="user-experince-expertise-first-module-btn" href="">
                Talk to an Expert{" "}
                <TrendingFlatIcon className="user-experince-expertise-first-module-btn-icon" />
              </a> */}
              <GlowingButton />
            </div>
          </div>
        </div>
      </div>
      <div className="user-experince-expertise-second-module-parent">
        <div class="container">
          <div className="user-experince-expertise-second-module-flex">
            <div className="user-experince-expertise-second-module-column one">
              <div data-aos="slide-right" className="user-experince-parralax-container">
                <h1 className="user-experince-stroke-font">User</h1>
                <h1 className="user-experince-stroke-font">Experience</h1>
              </div>
              <div className="user-experince-expertise-number-flex">
                <div data-aos="slide-up" className="user-experince-expertise-number-column">
                  <h1 className="clr-txt">19</h1>
                  <p className="clr-txt">Researchers &
                    Designers</p>
                </div>
                <div data-aos="slide-up" className="user-experince-expertise-number-column">
                  <h1 className="clr-txt">39</h1>
                  <p className="clr-txt">Researchers &
                    Designers</p>
                </div>
              </div>
            </div>
            <div data-aos="slide-left"  className="user-experince-expertise-second-module-column solid-bg">
              <div className="user-experince-expertise-solid-bg-content">
                <h1 className="clr-txt">DESIGN & CRAFTS</h1>
                <h3 className="clr-txt">We’re designers, developers & business
                  advisors. We build transformative experiences
                  for companies of any size.</h3>
                <ul className="user-experience-solid-bg-ul">
                  <li className="user-experience-solid-bg-list-items"><p className="clr-txt">UX Research</p></li>
                  <li className="user-experience-solid-bg-list-items"><p className="clr-txt">Digital Strategy</p></li>
                  <li className="user-experience-solid-bg-list-items"><p className="clr-txt">UI/UX Design</p></li>
                  <li className="user-experience-solid-bg-list-items"><p className="clr-txt">Mobile Apps</p></li>
                  <li className="user-experience-solid-bg-list-items"><p className="clr-txt">Websites</p></li>
                  <li className="user-experience-solid-bg-list-items"><p className="clr-txt">Operate & Support</p></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="user-experince-expertise-third-module-parent">
        <div className="container">
          <div className="user-experince-expertise-third-module">
            <h1 className="clr-text-white sub-title">
              Industries
            </h1>
            <h1 className="clr-text-white main-title">
              We Work With
            </h1>
            <ul className="user-experience-solid-bg-ul">
              <li className="user-experience-solid-bg-list-items"><p className="clr-txt">Apparel  </p></li>
              <li className="user-experience-solid-bg-list-items"><p className="clr-txt">Education</p></li>
              <li className="user-experience-solid-bg-list-items"><p className="clr-txt">Energy & Utilities</p></li>
              <li className="user-experience-solid-bg-list-items"><p className="clr-txt">Financial Services</p></li>
              <li className="user-experience-solid-bg-list-items"><p className="clr-txt">Healthcare</p></li>
              <li className="user-experience-solid-bg-list-items"><p className="clr-txt">Insurance</p></li>
              <li className="user-experience-solid-bg-list-items"><p className="clr-txt">Public Sector</p></li>
              <li className="user-experience-solid-bg-list-items"><p className="clr-txt">Retail</p></li>
              <li className="user-experience-solid-bg-list-items"><p className="clr-txt">Telecommunication</p></li>
              <li className="user-experience-solid-bg-list-items"><p className="clr-txt">& many more</p></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="user-experince-expertise-fourth-module-parent">
        <div className="container">
          <div className="user-experience-table-section">
            <table className="bordered user-experience-table" >
              <thead>
                <th className="user-experince-table-head" colSpan={2}><h1 className="clr-txt">OUR PROVEN DESIGN PROCESS</h1></th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p className="clr-txt-blue">01</p>
                    <h3>DEFINE</h3>
                    <h4>Understand your business and user to gain 
insights from various research methods.</h4>
                  </td>
                  <td>
                    <p className="clr-txt-blue">02</p>
                    <h3>
                    PLAN
                    </h3>
                    <h4>Explore insights to form and prioritize a 
strategic plan to impact objectives.</h4>
                    </td>
                </tr>
                <tr>
                  <td><p className="clr-txt-blue">03</p>
                  <h3>DEFINE</h3>
                  <h4>Explore insights to form and prioritize a 
strategic plan to impact objectives.</h4>
                  </td>
                  <td><p className="clr-txt-blue">04</p>
                  <h3>BUILD & IMPROVE</h3>
                  <h4>Explore insights to form and prioritize a 
strategic plan to impact objectives.</h4>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default UserExperience;
