import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-multi-carousel';
import './testimonial-carousel.css' 
import Card from 'react-bootstrap/Card';
import Rating from '@mui/material/Rating';

function Testimonials() {

  const [testimonial, setTestimonial] = useState([])
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  const fetchTestimonialData = () => {
    fetch("https://cms.prosouls.net/api/testimonial/getalldata")
      .then(response => {
        return response.json()
      })
      .then(data => {
        setTestimonial(data.data)

      })
  }
  useEffect(() => {
    fetchTestimonialData()

  }, [])
  return (
    <>
    <Carousel className='testimonial-carousel' responsive={responsive} arrows={false} infinite={true} autoPlay={true} autoPlaySpeed={5000}>
  {testimonial.map((item, idx) => (
    
    // <Carousel.Item key={idx} className='carousel-item'>
    
          <Card className='bg-blue-card'>
            <div className='testimonial-content'>
              {/* Display the content from the API item */}
              <div className='testimonial-desciption-text'>
                <p>{item.description}</p>
              </div>
            </div>
            <Card.Body>
              <div className='user-profile-parent'>
                <div className='testimonial-user-image'>
                  {/* Display the user image from the API item */}
                 <img src={"https://cms.prosouls.net/assets/testimonials/"+item.image} alt={''} />
                </div>
                <div>
                  <Card.Title className='clr-txt ps-1'>
                    {/* Display the card title from the API item */}
                   {item.title}
                  </Card.Title>
                  <Rating
                    name="half-rating-read"
                    defaultValue={item.rating}
                    precision={0.5}
                    readOnly
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
    
    // </Carousel.Item>
    
  ))}
</Carousel>
     
    </>
  );
}

export default Testimonials;