import globe from "../../assets/globe.jpg";
import logoHome from "../../assets/logoHome.png";
import "./home.css";
// import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
// import { motion } from "framer-motion";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
// import { Helmet } from "react-helmet";
import VideoLightbox from "../global-component/video-lightbox/video-ligthbox";
// import { gsap } from "gsap";
// const boxVariant = {
//     visible: { opacity: 1, scale: 1, transition: { duration: 0.1 } },
//     hidden: { opacity: 0, scale: 0 }
//   };

function Home() {
  useEffect(() => {
    Aos.init();
  });
  return (
    <>
      <div id="home1" className="Home1">
        <div className="banner-image-parent">
          <img
            width="100%"
            height="100%"
            className="center"
            alt="Prosouls"
            src={globe}
          />
        </div>
      </div>
      <div className="home">
        <div data-aos="zoom-in">
          <img
            width="12%"
            height="100%"
            className="homelogo"
            alt="Prosouls"
            src={logoHome}
          />
        </div>

        <div>
          <h3 className="Heading">CREATING SENSE DIGITALLY</h3>
        </div>
        <div>
          <p className="text">
            Prosouls is a dynamic team of enthusiastic and inventive experts
            committed to delivering exceptional outcomes through their
            unwavering dedication and optimistic approach. With years of
            industry expertise, our objective is to offer precisely tailored IT
            services, generating compelling digital communications. Our primary
            aim is to enhance our clients prominence and transform their
            concepts into reality.
          </p>
        </div>
        <VideoLightbox />
      </div>
    </>
  );
}

export default Home;
