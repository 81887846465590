import "bootstrap/dist/css/bootstrap.min.css";
import "../../careers/careers";
// import Navbarr from "../../navbar/navbar";
import { useEffect } from "react";
import * as React from "react";
import ParticlesBackground from "../../global-component/particle-background/particle-background";
import "./application-modernization.css";
// import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import Footer from "../../footer/footer";
import GlowingButton from "../../global-component/glowing-btn/glowing-btn";
import MicrosoftImage1 from '../../../assets/application-modernization/application-modernization-azure.webp'
import MicrosoftImage2 from '../../../assets/application-modernization/application-modernization-partner-1.webp'
import MicrosoftImage3 from '../../../assets/application-modernization/application-modernization-partner-2.webp'
import Aos from 'aos';
import 'aos/dist/aos.css'
function ApplicationModernization() {
  useEffect(() => {
    Aos.init({ duration: 2000 })
  })
  return (
    <>
      <ParticlesBackground />
      <div className="application-modernization-expertise-banner">
        <div class="container">
          <div class="application-modernization-expertise-first-module">
            <h5 className="clr-text-white text-uppercase">
              APPLICATION MODERNIZATION
            </h5>
            <h1 className="clr-text-white">
              Create the agile enterprise
              of the future
            </h1>
            <div className="application-modernization-expertise-first-module-btn-parent">
              <GlowingButton />
            </div>
          </div>
        </div>
      </div>
      <div className="application-modernization-expertise-second-module-parent">
        <div class="container">
          <div className="application-modernization-expertise-second-module-flex">
            <div className="application-modernization-expertise-second-module-column one">
              <div data-aos="slide-right" className="application-modernization-parralax-container">
                <h1 className="application-modernization-stroke-font">Application</h1>
                <h1 className="application-modernization-stroke-font">Modernization</h1>
              </div>
            </div>
            <div data-aos="slide-left" className="application-modernization-expertise-second-module-column solid-bg">
              <div className="application-modernization-expertise-solid-bg-content">
                <p className="clr-txt application-modernization-content">Legacy applications are at the heart of how many
                  enterprises function, they are holding organizations
                  back to deliver modernized experiences for employees,
                  partners, and customers through next-generation
                  digital technologies.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <section class="application-modernization-third-module-section">
          <div class="application-modernization-third-module-section__item column-one">
            <img alt="Microsoft partner" width="100%" height="100%" className="application-modernization-microsoft-partner" src={MicrosoftImage2} />
            <img alt="Microsoft partner" width="100%" height="100%" className="application-modernization-microsoft-partner my-3" src={MicrosoftImage3} />
            <p className="clr-txt mng-line-height mb-3">
              Being Microsoft Gold Partner (Cloud Platform),
              and Microsoft Advanced Specialization Partner for
              Modernization of Web Applications to Azure, our
              team possesses full-stack cloud expertise in
              front-end, back-end, data, intelligence, and
              DevOps to help our clients at every step of their
              cloud journey.
            </p>
          </div>

          <div class="application-modernization-third-module-section__item column-two">
            <h1 className="clr-txt mb-5">HIGH PRODUCTIVITY</h1>
            <p className="clr-txt mng-line-height my-3">
              Our cloud consultants are skilled and equipped to help in building native
              applications, Azure services, and DevOps.
            </p>
            <p className="clr-txt mng-line-height my-3">
              Introducing cloud enablement in your technology ecosystem and building
              apps on the cloud has multiple, rigorous steps which can be made easy with
              the guidance of our experts.
            </p>
            <p className="clr-txt mng-line-height my-3">
              Covering all aspects of modernizing applications from consulting to migrating
              and managing, we provide assistance in ensuring a smooth transition while
              keeping costs at a minimum.
            </p>
            <p className="clr-txt mng-line-height my-3">
              Find out how we can transform your legacy applications.
            </p>
            <p className="clr-txt mng-line-height my-3">
              Contact Us
            </p>
          </div>
        </section>
      </div>
      <div className="container my-5">
        <section class="application-modernization-fourth-module-section">
          <div class="application-modernization-fourth-module-section__item column-one">
            <p className="clr-txt mng-line-height">
              By transitioning their legacy applications to a better infrastructure
              such as Microsoft Azure, enterprises of all sizes can take advantage
              of the cloud. This process of modernizing applications faster is
              made possible by Azure due to the use of containers that brings
              together you business needs, covering products and services,
              along with third-party applications as well.
            </p>
          </div>
          <div class="application-modernization-fourth-module-section__item">
            <img alt="Azure" width="100%" height="100%" data-aos="fade-up"
              data-aos-anchor-placement="top-center" className="application-modernization-table-img" src={MicrosoftImage1} />
          </div>
        </section>
      </div>
      <div className="application-moderniation-fourth-module-parent">
        <div className="container">
          <div className="application-moderniation-tech-flex">
            <div className="application-moderniation-tech-column">
              <h4 className="clr-txt">Security</h4>
              <p className="clr-txt">
                Leverage better security protocols that
                provide applications with end-to-end
                protection against potential attacks
                and other leaks
              </p>
            </div>
            <div className="application-moderniation-tech-column">
              <h4 className="clr-txt">Compliance</h4>
              <p className="clr-txt">
                Overcoming challenges of legacy
                systems Azure cloud ensures regula
                tory compliance by regularly staying
                up to date with new updates and
                guidelines
              </p>
            </div>
            <div className="application-moderniation-tech-column">
              <h4 className="clr-txt">Data Management</h4>
              <p className="clr-txt">
                Overcoming challenges of legacy
                systems Azure cloud ensures regula
                tory compliance by regularly staying
                up to date with new updates and
                guidelines
              </p>
            </div>
            <div className="application-moderniation-tech-column">
              <h4 className="clr-txt">Speed</h4>
              <p className="clr-txt">
                Equip your business with enhanced
                agility and speed that are required to
                quickly respond to changing market
                dynamics
              </p>
            </div>
            <div className="application-moderniation-tech-column">
              <h4 className="clr-txt">Cost Optimization</h4>
              <p className="clr-txt">
                Explore new opportunities quickly
                with a much lower cost. Scale capacity
                to current demand while allowing for
                future growth
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ApplicationModernization;
