import "bootstrap/dist/css/bootstrap.min.css";
import "../../careers/careers";
import Navbarr from "../../navbar/navbar";
import { useState, useEffect } from "react";
import * as React from "react";
import ParticlesBackground from "../../global-component/particle-background/particle-background";
import Stylesheet from "./application-development.css";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import Footer from "../../footer/footer";
import GlowingButton from "../../global-component/glowing-btn/glowing-btn";
import FrontTechImage1 from "../../../assets/application-development/angular-logo.webp";
import FrontTechImage2 from "../../../assets/application-development/typescript-logo.webp";
import FrontTechImage3 from "../../../assets/application-development/bootstrap-logo.webp";
import FrontTechImage4 from "../../../assets/application-development/vue-logo.webp";
import FrontTechImage5 from "../../../assets/application-development/javascript-logo.webp";
import FrontTechImage6 from "../../../assets/application-development/material-ui-logo.webp";
import FrontTechImage7 from "../../../assets/application-development/react-logo.webp";
import MobilityTechImage1 from "../../../assets/application-development/objective-c-logo.webp";
import MobilityTechImage2 from "../../../assets/application-development/java-cotlin-logo.webp";
import MobilityTechImage3 from "../../../assets/application-development/react-native-logo.webp";
import BackendTechImage1 from "../../../assets/application-development/net-logo.webp";
import BackendTechImage2 from "../../../assets/application-development/rust-logo.webp";
import BackendTechImage3 from "../../../assets/application-development/power-buulder-logo.webp";
import BackendTechImage4 from "../../../assets/application-development/mysql-logo.webp";
import BackendTechImage5 from "../../../assets/application-development/java-logo.webp";
import BackendTechImage6 from "../../../assets/application-development/python-logo.webp";
import BackendTechImage7 from "../../../assets/application-development/postgre.webp";
import BackendTechImage8 from "../../../assets/application-development/infinity-visual-studio-logo.webp";
import DevopsTechImage1 from "../../../assets/application-development/docker-logo.webp";
import DevopsTechImage2 from "../../../assets/application-development/git-logo.webp";
import DevopsTechImage3 from "../../../assets/application-development/kubernates-logo.webp";
import DevopsTechImage4 from "../../../assets/application-development/puppet-logo.webp";
import DevopsTechImage5 from "../../../assets/application-development/jenkins-logo.webp";
import Aos from 'aos';
import 'aos/dist/aos.css'
function ApplicationDevelopment() {
  useEffect(() => {
    Aos.init({ duration: 2000 })
  })
  return (
    <>
      <ParticlesBackground />
      <div className="application-development-expertise-banner">
        <div class="container">
          <div class="application-development-expertise-first-module">
            <h5 className="clr-text-white text-uppercase">
              APPLICATION DEVELOPMENT
            </h5>
            <h1 className="clr-text-white">
              Purpose-built digital solutions
              that drive enterprise-wide
              growth and productivity
            </h1>
            <div className="application-development-expertise-first-module-btn-parent">
              <GlowingButton />
            </div>
          </div>
        </div>
      </div>
      <div className="application-development-expertise-second-module-parent">
        <div class="container">
          <div className="application-development-expertise-second-module-flex">
            <div className="application-development-expertise-second-module-column one">
              <div data-aos="slide-right" className="application-development-parralax-container">
                <h1 className="application-development-stroke-font">Application</h1>
                <h1 className="application-development-stroke-font">Development</h1>
              </div>
            </div>
            <div data-aos="slide-left" className="application-development-expertise-second-module-column solid-bg">
              <div className="application-development-expertise-solid-bg-content">
                <p className="clr-txt application-development-content">The unique character of your business is what gives it a competitive edge. Custom developed applications help you take that edge further.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container application-development-third-module-container my-5">
        <section class="application-development-third-module-section">
          <div class="application-development-third-module-section__item column-one">
            <p className="clr-txt mng-line-height mb-3">
              By continually adapting to technological advances
              and modern trends, Prosouls always remains at
              the forefront of application development. Our
              experienced and certified development teams use
              leading-edge methodologies to design, develop,
              implement, and continually enhance innovative
              business solutions for all major platforms and
              devices. Our cloud, desktop, and mobile applications
              offer the best balance of performance, usability,
              cost-efficiency, resiliency, and maintainability to help
              you increase business ROI.
            </p>
          </div>

          <div class="application-development-third-module-section__item column-two">
            <h1 className="clr-txt mb-5">Web & Desktop Enterprise Applications</h1>
            <p className="clr-txt mng-line-height my-3">
              Local and international clients across all major industries have digitized and enhanced their
              internal and customer-facing operations using web applications created by Prosouls We use
              a wide range of proprietary and open-source modern technologies and frameworks to produce
              powerful and user-friendly applications that users can access from any modern web browser.
            </p>
            <p className="clr-txt mng-line-height my-3">
              Prosouls also provides native and cross-platform desktop application development services
              for all major operating systems with excellent performance and a rich user experience.
            </p>
          </div>
        </section>
      </div>
      <div className="container my-5">
        <div className="application-development-techs-title">
          <h1 className="clr-txt text-center">FRONT-END</h1>
          <div class="application-development-tech-container">
            <div class="application-development-tech-item">
              <img alt="Angular" width="100%" height="100%" className="application-development-tech-img" src={FrontTechImage1} />
            </div>
            <div class="application-development-tech-item">
              <img alt="Typescript" width="100%" height="100%" className="application-development-tech-img" src={FrontTechImage2} />
            </div>
            <div class="application-development-tech-item">
              <img alt="Bootstrap" width="100%" height="100%" className="application-development-tech-img" src={FrontTechImage3} />
            </div>
            <div class="application-development-tech-item">
              <img alt="Vuejs" width="100%" height="100%" className="application-development-tech-img" src={FrontTechImage4} />
            </div>
            <div class="application-development-tech-item">
              <img alt="Javascript" width="100%" height="100%" className="application-development-tech-img" src={FrontTechImage5} />
            </div>
            <div class="application-development-tech-item">
              <img alt="Material UI" width="100%" height="100%" className="application-development-tech-img" src={FrontTechImage6} />
            </div>
            <div class="application-development-tech-item">
              <img alt="React" width="100%" height="100%" className="application-development-tech-img" src={FrontTechImage7} />
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="application-development-techs-title">
          <h1 className="clr-txt text-center">MOBILITY</h1>
          <div class="application-development-tech-container">
            <div class="application-development-tech-item">
              <img alt="Objective C" width="100%" height="100%" className="application-development-tech-img" src={MobilityTechImage1} />
            </div>
            <div class="application-development-tech-item">
              <img alt="Java Cotlin" width="100%" height="100%" className="application-development-tech-img" src={MobilityTechImage2} />
            </div>
            <div class="application-development-tech-item">
              <img alt="React Native" width="100%" height="100%" className="application-development-tech-img" src={MobilityTechImage3} />
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="application-development-techs-title">
          <h1 className="clr-txt text-center">BACK-END</h1>
          <div class="application-development-tech-container">
            <div class="application-development-tech-item">
              <img alt=".Net" width="100%" height="100%" className="application-development-tech-img" src={BackendTechImage1} />
            </div>
            <div class="application-development-tech-item">
              <img alt="Rust" width="100%" height="100%" className="application-development-tech-img" src={BackendTechImage2} />
            </div>
            <div class="application-development-tech-item">
              <img alt="Power builder" width="100%" height="100%" className="application-development-tech-img" src={BackendTechImage3} />
            </div>
            <div class="application-development-tech-item">
              <img alt="My SQL" width="100%" height="100%" className="application-development-tech-img" src={BackendTechImage4} />
            </div>
            <div class="application-development-tech-item">
              <img alt="Java" width="100%" height="100%" className="application-development-tech-img" src={BackendTechImage5} />
            </div>
            <div class="application-development-tech-item">
              <img alt="Python" width="100%" height="100%" className="application-development-tech-img" src={BackendTechImage6} />
            </div>
            <div class="application-development-tech-item">
              <img alt="Postgre SQL" width="100%" height="100%" className="application-development-tech-img" src={BackendTechImage7} />
            </div>
            <div class="application-development-tech-item">
              <img alt="Visual Studio Infinity" width="100%" height="100%" className="application-development-tech-img" src={BackendTechImage8} />
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="application-development-techs-title">
          <h1 className="clr-txt text-center">DEVOPS TOOLS</h1>
          <div class="application-development-tech-container devops-tech-container">
            <div class="application-development-tech-item devops-tech-item">
              <img alt="Docker" width="100%" height="100%" className="application-development-tech-img" src={DevopsTechImage1} />
            </div>
            <div class="application-development-tech-item devops-tech-item">
              <img alt="Git" width="100%" height="100%" className="application-development-tech-img" src={DevopsTechImage2} />
            </div>
            <div class="application-development-tech-item devops-tech-item">
              <img alt="Kubernetes" width="100%" height="100%" className="application-development-tech-img" src={DevopsTechImage3} />
            </div>
            <div class="application-development-tech-item devops-tech-item">
              <img alt="Puppet" width="100%" height="100%" className="application-development-tech-img" src={DevopsTechImage4} />
            </div>
            <div class="application-development-tech-item devops-tech-item">
              <img alt="Jenkins" width="100%" height="100%" className="application-development-tech-img" src={DevopsTechImage5} />
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="application-development-info-card-parent">
          <div className="application-development-info-card-flex">
            <div className="application-development-info-card-column">
              <h3 className="clr-txt text-center">Cloud-Native Modernization (PaaS)</h3>
              <p className="clr-txt text-center">
                Adapt your legacy apps to modern cloud architecture with
                lightweight Docker containers and microservices architecture
                to minimize infrastructure spend and enhance agility
              </p>
            </div>
            <div className="application-development-info-card-column">
              <h3 className="clr-txt text-center">Modernization Assessment</h3>
              <p className="clr-txt text-center">
                We determine the most appropriate strategy for
                modernizing your applications and propose a detailed roadmap
              </p>
            </div>
          </div>
          <div className="application-development-info-card-flex">
            <div className="application-development-info-card-column">
              <h3 className="clr-txt text-center">Application Migration</h3>
              <p className="clr-txt text-center">
                Improve the efficiency, agility, and user experience of onpremises legacy applications by migrating them to modern
                technology and open-source frameworks
              </p>
            </div>
            <div className="application-development-info-card-column">
              <h3 className="clr-txt text-center">Lift-and-Shift (IaaS)</h3>
              <p className="clr-txt text-center">
                Quickly and cost-efficiently migrate your legacy applications’
                resources as-is from on-premises infrastructure to your cloud
                platform of choice
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div class="application-development-design-heading">
          <h1 className="clr-txt">MANAGED SERVICES</h1>
          <div className="application-development-manage-services-content">
            <p className="clr-txt text-center">
              To support your growing business needs, choose Prosouls to monitor, maintain, and enhance your applications and infrastructure. Our
              managed support team identifies bottlenecks to keep your applications running at peak performance, and proactively recommends ways to
              automate business functions and deliver maximum value
            </p>
          </div>
        </div>
        <div className="application-development-info-card-flex">
          <div className="application-development-info-card-column manage-service">
            <h3 className="clr-txt">Infrastructure & application monitoring</h3>
            <p className="clr-txt">
              Our cloud-based management service collects real-time data and
              offers actionable insights on your IT footprint for enhanced decision-making.
            </p>
            <div className="application-development-manage-service-inner-flex">
              <div className="application-development-manage-service-inner-column">
                <p className="clr-txt m-0">
                  Azure Monitor
                </p>
              </div>
              <div className="application-development-manage-service-inner-column">
                <p className="clr-txt m-0">
                  JIRA
                </p>
              </div>
              <div className="application-development-manage-service-inner-column">
                <p className="clr-txt m-0">
                  Condluence
                </p>
              </div>
            </div>
          </div>
          <div className="application-development-info-card-column manage-service">
            <h3 className="clr-txt">BPO Automation</h3>
            <p className="clr-txt ">
              The accuracy and efficiency of repetitive business tasks by switching
              from outsourcing to intelligent automation technologies.
            </p>
            <div className="application-development-manage-service-inner-flex">
              <div className="application-development-manage-service-inner-column">
                <p className="clr-txt m-0">
                  UiPath
                </p>
              </div>
              <div className="application-development-manage-service-inner-column">
                <p className="clr-txt m-0">
                  Blue Prism
                </p>
              </div>
              <div className="application-development-manage-service-inner-column">
                <p className="clr-txt m-0">
                  Automation Anywhere
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ApplicationDevelopment;
